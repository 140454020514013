{
  "translation": {
    "aboutThisItem": "Sobre esse item",
    "addedFromUrl": "(Adicionado por URL)",
    "addManifestUrl": "Localização do manifesto",
    "addManifestUrlHelp": "URL do manifesto IIIF",
    "addResource": "Adicionar conteúdo",
    "annotationCanvasLabel_1/1": "Item: [{{label}}]",
    "annotationCanvasLabel_1/2": "Esquerda: [{{label}}]",
    "annotationCanvasLabel_2/2": "Direita: [{{label}}]",
    "annotations": "Anotações",
    "attribution": "Atribuições",
    "attributionTitle": "Direitos",
    "authenticationFailed": "Falha na autenticação.",
    "authenticationRequired": "Autenticação necessária para acesso completo",
    "backToResults": "Voltar aos resultados",
    "book": "Livro",
    "bottom": "Embaixo",
    "cancel": "Cancelar",
    "canvasIndex": "Índice",
    "changeTheme": "Mudar tema",
    "clearSearch": "limpar",
    "closeAddResourceForm": "Fechar formulário",
    "closeAddResourceMenu": "Fechar lista de conteúdo",
    "closeCompanionWindow": "Fechar painel",
    "closeWindow": "Fechar janela",
    "collapseSection": "Suprimir seção \"{{section}}\"",
    "collapseSidePanel": "Suprimir barra lateral",
    "itemList": "Lista compacta",
    "continue": "Continuar",
    "copy": "Copiar",
    "currentItem": "Item atual",
    "currentItem_1/1": "Item atual",
    "currentItem_1/2": "Esquerda",
    "currentItem_2/2": "Direita",
    "dark": "Tema escuro",
    "dismiss": "Cancelar",
    "highlightAllAnnotations": "Exibir todas",
    "downloadExport": "Exportar área de trabalho",
    "downloadExportWorkspace": "Exportar área de trabalho",
    "elastic": "Elástico",
    "elasticDescription": "Mova e redimensione janelas livremente numa área de trabalho ilimitada. Janelas podem se sobrepor.",
    "emptyResourceList": "Sua lista de conteúdos está vazia",
    "errorDialogConfirm": "OK",
    "errorDialogTitle": "Um erro ocorreu",
    "exitFullScreen": "Sair do modo tela cheia",
    "expandSection": "Expandir seção \"{{section}}\"",
    "expandSidePanel": "Expandir barra lateral",
    "fetchManifest": "Adicionar",
    "fullScreen": "Tela cheia",
    "gallery": "Galeria",
    "hideZoomControls": "Ocultar controles de zoom",
    "iiif_homepage": "Sobre esse conteúdo",
    "iiif_manifest": "Manifesto IIIF",
    "iiif_renderings": "Outros formatos",
    "iiif_seeAlso": "Veja também",
    "import" : "Importar",
    "importWorkspace": "Importar área de trabalho",
    "importWorkspaceHint": "Cole uma configuração do Mirador 3 para importar",
    "item": "Item: {{label}}",
    "language": "Idioma",
    "light": "Tema claro",
    "links": "Links",
    "listAllOpenWindows": "Pular para janela",
    "login": "Login",
    "manifestError": "O conteúdo não pode ser adicionado:",
    "maximizeWindow": "Maximizar janela",
    "minimizeWindow": "Minimizar janela",
    "mirador": "Mirador",
    "miradorResources": "conteúdo Mirador",
    "miradorViewer": "visualizador Mirador",
    "more": "mais...",
    "moreResults": "Mais resultados",
    "mosaic": "Mosáico",
    "mosaicDescription": "Mova e redimensione janelas em relação entre si, dentro de uma área visível (grid).",
    "moveCompanionWindowToBottom": "Mover para baixo",
    "moveCompanionWindowToRight": "Mover para direita",
    "nextCanvas": "Próximo item",
    "numItems": "{{number}} itens",
    "off": "Desativado",
    "openCompanionWindow_annotations": "Anotações",
    "openCompanionWindow_attribution": "Direitos",
    "openCompanionWindow_canvas": "Índice",
    "openCompanionWindow_info": "Informações",
    "openCompanionWindow_search": "Busca",
    "openInCompanionWindow": "Abrir em janela separada",
    "openWindows": "Janelas abertas",
    "pagination": "{{current}} de {{total}}",
    "position": "Posição",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "Item anterior",
    "related": "Relacionado",
    "resource": "Conteúdo",
    "retry": "Tentar novamente",
    "right": "Direita",
    "rights": "Licenças",
    "searchInputLabel": "termos de busca",
    "searchNextResult": "Próximo resultado",
    "searchNoResults": "Nenhum resultado encontrado",
    "searchPreviousResult": "Resultado anterior",
    "searchSubmitAria": "Realizar busca",
    "searchTitle": "Busca",
    "selectWorkspaceMenu": "Selecione um tipo de área de trabalho",
    "showingNumAnnotations": "Mostrando {{number}} anotações",
    "showZoomControls": "Mostrar controles de zoom",
    "sidebarPanelsNavigation": "Navegação por paineis na barra lateral",
    "single": "Individual",
    "startHere": "Comece aqui",
    "suggestSearch": "Procurar neste documento por \"{{ query }}\"",
    "theme": "Tema",
    "thumbnailList": "Lista de thumbnails",
    "thumbnailNavigation": "Thumbnails",
    "thumbnails": "Thumbnails",
    "toggleWindowSideBar": "Ver barra lateral",
    "tryAgain": "Tente novamente",
    "untitled": "[Sem título]",
    "view": "Visualizações",
    "welcome": "Bem-vindo ao Mirador",
    "window": "Janela: {{label}}",
    "windowMenu": "Opções de visualização de janelas e thumbnails",
    "windowNavigation": "Navegação de janelas",
    "windowPluginButtons": "Opções",
    "windowPluginMenu": "Opções de janela",
    "workspace": "Área de trabalho",
    "workspaceFullScreen": "Tela cheia",
    "workspaceMenu": "Configurações de área de trabalho",
    "workspaceOptions": "Opções de área de trabalho",
    "workspaceSelectionTitle": "Selecionar tipo de área de trabalho",
    "zoomIn": "Mais zoom",
    "zoomOut": "Menos zoom",
    "zoomReset": "Zerar zoom"
  }
}
