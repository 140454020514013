{
  "translation": {
    "aboutThisItem": "有关此物件",
    "addedFromUrl": "(从URL添加)",
    "addManifestUrl": "来源",
    "addManifestUrlHelp": "IIIF资源的URL",
    "addResource": "添加资源",
    "annotationCanvasLabel_1/1": "物件: [{{label}}]",
    "annotationCanvasLabel_1/2": "左方: [{{label}}]",
    "annotationCanvasLabel_2/2": "右方: [{{label}}]",
    "annotations": "注释",
    "attribution": "着作权",
    "attributionTitle": "着作权",
    "book": "书籍",
    "bottom": "下方",
    "cancel": "取消",
    "canvasIndex": "索引",
    "changeTheme": "变更佈景主题",
    "closeAddResourceForm": "关闭表格",
    "closeAddResourceMenu": "关闭资源列表",
    "closeCompanionWindow": "关闭附属视窗",
    "closeWindow": "关闭视窗",
    "collapseSection": "关闭{{section}}分页",
    "collapseSidePanel": "关闭边栏",
    "itemList": "标题列表",
    "copy": "複製",
    "currentItem": "目前物件",
    "currentItem_1/1": "目前物件",
    "currentItem_1/2": "左方",
    "currentItem_2/2": "右方",
    "dark": "黑色主题",
    "dismiss": "关闭信息",
    "highlightAllAnnotations": "显示所有注释",
    "downloadExport": "滙出桌面排版",
    "downloadExportWorkspace": "滙出桌面排版",
    "elastic": "弹性",
    "elasticDescription": "在桌面上自由摆放视窗",
    "emptyResourceList": "资源列表没有物件",
    "errorDialogConfirm": "确定",
    "errorDialogTitle": "发生错误",
    "exitFullScreen": "退出全萤幕",
    "expandSection": "开启{{section}}分页",
    "expandSidePanel": "开启边栏",
    "fetchManifest": "添加",
    "fullScreen": "全萤幕",
    "gallery": "矩列",
    "hideZoomControls": "隐藏缩放选项",
    "iiif_homepage": "有关此资源",
    "iiif_manifest": "IIIF",
    "iiif_renderings": "其他格式",
    "iiif_seeAlso": "另见",
    "import" : "滙入",
    "importWorkspace": "滙入桌面排版",
    "importWorkspaceHint": "在此贴上Mirador 3排版设定码",
    "item": "物件: {{label}}",
    "language": "语言",
    "light": "白色主题",
    "links": "连结",
    "listAllOpenWindows": "切换至视窗",
    "login": "登入",
    "manifestError": "无法增添资源:",
    "maximizeWindow": "视窗最大化",
    "minimizeWindow": "视窗最小化",
    "mirador": "Mirador",
    "miradorResources": "Mirador资源",
    "miradorViewer": "Mirador阅览器",
    "mosaic": "马赛克",
    "mosaicDescription": "在桌面上以格状方式排列视窗",
    "moveCompanionWindowToBottom": "移至下方",
    "moveCompanionWindowToRight": "移至右方",
    "nextCanvas": "下一页",
    "numItems": "{{number}} 项物件",
    "off": "关闭",
    "openCompanionWindow_annotations": "注释",
    "openCompanionWindow_attribution": "着作权",
    "openCompanionWindow_canvas": "目录",
    "openCompanionWindow_info": "资讯",
    "openInCompanionWindow": "移至新附属视窗",
    "openWindows": "现有视窗",
    "pagination": "{{current}} / {{total}}",
    "position": "位置",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "上一页",
    "related": "相关资讯",
    "resource": "资源",
    "right": "右方",
    "rights": "版权",
    "selectWorkspaceMenu": "选择桌面排版方式",
    "showingNumAnnotations": "显示 {{number}} 项注释",
    "showZoomControls": "显示缩放选项",
    "sidebarPanelsNavigation": "切换边栏",
    "single": "单项",
    "startHere": "按此开始",
    "theme": "佈景主题",
    "thumbnailList": "缩图列表",
    "thumbnailNavigation": "缩图",
    "thumbnails": "显示缩图",
    "toggleWindowSideBar": "切换边栏开关",
    "tryAgain": "请重试",
    "untitled": "[无标题]",
    "view": "物件排列方式",
    "welcome": "欢迎使用Mirador",
    "window": "视窗: {{label}}",
    "windowMenu": "视窗选项",
    "windowNavigation": "切换视窗",
    "windowPluginButtons": "选项",
    "workspace": "桌面",
    "workspaceFullScreen": "全萤幕",
    "workspaceMenu": "桌面设定",
    "workspaceOptions": "桌面选项",
    "workspaceSelectionTitle": "选择桌面排版方式",
    "zoomIn": "放大",
    "zoomOut": "放小",
    "zoomReset": "重设缩放"
  }
}
