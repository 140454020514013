{
  "translation": {
    "aboutMirador": "Mirador 프로젝트에 대하여",
    "aboutThisItem": "해당 아이템에 대하여",
    "addedFromUrl": "(URL에서 추가됨)",
    "addManifestUrl": "리소스의 위치",
    "addManifestUrlHelp": "IIIF 리소스의 URL",
    "addResource": "리소스 추가",
    "annotationCanvasLabel_1/1": "아이템: [{{label}}]",
    "annotationCanvasLabel_1/2": "왼쪽: [{{label}}]",
    "annotationCanvasLabel_2/2": "오른쪽: [{{label}}]",
    "annotations": "주석",
    "attribution": "귀속",
    "attributionTitle": "권리",
    "authenticationFailed": "인증 실패.",
    "authenticationRequired": "모든 정보에 접근하기 위해선 인증이 필요합니다.",
    "backToResults": "결과로 돌아가기",
    "book": "책",
    "bottom": "아래",
    "cancel": "취소",
    "canvasIndex": "인덱스",
    "changeTheme": "테마 변경",
    "clearSearch": "지우기",
    "close": "닫기",
    "closeAddResourceForm": "폼 닫기",
    "closeAddResourceMenu": "리소스 목록 닫기",
    "closeCompanionWindow": "패널 닫기",
    "closeWindow": "윈도우 닫기",
    "collapseSection": "\"{{section}}\" 섹션 접기",
    "collapseSidePanel": "사이드바 접기",
    "collection": "컬렉션",
    "itemList": "아이템 목록",
    "continue": "계속하기",
    "copy": "복사",
    "currentItem": "현재 아이템",
    "currentItem_1/1": "현재 아이템",
    "currentItem_1/2": "왼쪽",
    "currentItem_2/2": "오른쪽",
    "dark": "다크 모드",
    "digitizedView": "디지털 뷰",
    "dismiss": "무시하기",
    "highlightAllAnnotations": "모두 하이라이트하기",
    "displayNoAnnotations": "하이라이트 해제",
    "downloadExport": "작업공간 내보내기",
    "downloadExportWorkspace": "작업공간 내보내기",
    "elastic": "신축성",
    "elasticDescription": "무한한 작업공간에서 윈도우를 자유롭게 움직이고 조절해보세요. 윈도우는 서로 겹칠 수 있습니다.",
    "emptyResourceList": "리소스 목록이 비어있습니다",
    "error": "오류",
    "errorDialogConfirm": "허락",
    "errorDialogTitle": "오류 발생",
    "exitFullScreen": "전체화면에서 나가기",
    "expandSection": "\"{{section}}\" 섹션 확장",
    "expandSidePanel": "사이드바 확장",
    "exportCopied": "작업공간 환경설정을 클립보드에 복사했습니다",
    "fetchManifest": "추가하기",
    "fullScreen": "전체화면",
    "gallery": "갤러리",
    "hideZoomControls": "확대/축소 기능 숨기기",
    "iiif_homepage": "해당 리소스에 대하여",
    "iiif_manifest": "IIIF 매니페스트",
    "iiif_renderings": "대체 포맷",
    "iiif_seeAlso": "참고",
    "import" : "가져오기",
    "importWorkspace": "작업공간 가져오기",
    "importWorkspaceHint": "Mirador 3 환경설정 가져와 붙여넣기",
    "item": "아이템: {{label}}",
    "jsError": "기술적인 세부사항",
    "jsStack": "{{ stack }}",
    "language": "언어",
    "layer_hide": "레이어 숨기기",
    "layer_move": "레이어 움직이기",
    "layer_opacity": "레이어 불투명도",
    "layer_show": "레이어 보이기",
    "layer_moveToTop": "레이어를 위로 옮기기",
    "layers": "레이어",
    "light": "라이트 모드",
    "links": "링크",
    "listAllOpenWindows": "윈도우로 이동하기",
    "login": "로그인",
    "logout": "로그아웃",
    "manifestError": "리소스를 추가할 수 없습니다:",
    "maximizeWindow": "윈도우 크기 최대화",
    "minimizeWindow": "윈도우 크기 최소화",
    "mirador": "Mirador",
    "miradorResources": "Mirador 리소스",
    "miradorViewer": "Mirador 뷰어",
    "more": "더 보기...",
    "moreResults": "결과 더 보기",
    "mosaic": "모자이크",
    "mosaicDescription": "프레임 내에서 윈도우를 이동하고 크기를 조정합니다.",
    "moveCompanionWindowToBottom": "아래로 옮기기",
    "moveCompanionWindowToRight": "오른쪽으로 옮기기",
    "nextCanvas": "다음 아이템",
    "noItemSelected": "아이템이 선택되지 않았습니다",
    "numItems": "{{number}}개의 아이템",
    "numItems_plural": "{{number}}개의 아이템",
    "off": "끄기",
    "openCompanionWindow_annotations": "주석",
    "openCompanionWindow_attribution": "권리",
    "openCompanionWindow_canvas": "인덱스",
    "openCompanionWindow_info": "정보",
    "openCompanionWindow_layers": "레이어",
    "openCompanionWindow_search": "검색",
    "openInCompanionWindow": "별도의 패널에서 열기",
    "openWindows": "현재 열려있는 윈도우",
    "pagination": "{{current}} of {{total}}",
    "position": "배치",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "이전 아이템",
    "related": "관련",
    "resource": "리소스",
    "retry": "재시도",
    "right": "오른쪽",
    "rights": "라이선스",
    "scroll": "스크롤",
    "searchInputLabel": "용어 찾기",
    "searchNextResult": "다음 결과",
    "searchNoResults": "해당 결과 없음",
    "searchPreviousResult": "이전 결과",
    "searchResultsRemaining": "{{numLeft}}개 남음",
    "searchSubmitAria": "검색하기",
    "searchTitle": "검색",
    "selectWorkspaceMenu": "작업공간 유형 선택",
    "showingNumAnnotations": "{{number}}개의 주석 나타내기",
    "showingNumAnnotations_plural": "{{number}}개의 주석 나타내기",
    "showCollection": "컬렉션 보이기",
    "showZoomControls": "확대/축소 기능 보이기",
    "sidebarPanelsNavigation": "사이드바 패널 탐색",
    "single": "한 개",
    "startHere": "시작하기",
    "suggestSearch": "이 문서를 \"{{ query }}\"로 검색하기",
    "tableOfContentsList": "목차",
    "theme": "테마",
    "thumbnailList": "썸네일 목록",
    "thumbnailNavigation": "썸네일",
    "thumbnails": "썸네일",
    "toggleWindowSideBar": "사이드바 전환",
    "totalCollections": "{{count}}개의 컬렉션",
    "totalCollections_plural": "{{count}}개의 컬렉션",
    "totalManifests": "{{count}}개의 매니페스트",
    "totalManifests_plural": "{{count}}개의 매니페스트",
    "tryAgain": "다시 시도하세요",
    "untitled": "[타이틀 없음]",
    "view": "뷰",
    "viewWorkspaceConfiguration": "작업공간 환경설정 ㅂ괴",
    "welcome": "Mirador에 오신 것을 환영합니다",
    "window": "윈도우: {{label}}",
    "windowMenu": "윈도우 뷰 & 썸네일 표시",
    "windowNavigation": "윈도우 탐색",
    "windowPluginButtons": "옵션",
    "windowPluginMenu": "윈도우 옵션",
    "workspace": "작업공간",
    "workspaceNavigation": "작업공간 탐색",
    "workspaceFullScreen": "전체화면",
    "workspaceMenu": "작업공간 설정",
    "workspaceOptions": "작업공간 옵션",
    "workspaceSelectionTitle": "작업공간 유형을 선택하세요",
    "zoomIn": "확대",
    "zoomOut": "축소",
    "zoomReset": "줌 재설정"
  }
}
