{
  "translation": {
    "aboutMirador": "О пројекту Mirador",
    "aboutThisItem": "Опис",
    "addedFromUrl": "(Додато са URL)",
    "addManifestUrl": "Локација ресурса",
    "addManifestUrlHelp": "URL IIIF ресурса",
    "addResource": "Додајте ресурс",
    "annotationCanvasLabel_1/1": "Страница: [{{label}}]",
    "annotationCanvasLabel_1/2": "Лева: [{{label}}]",
    "annotationCanvasLabel_2/2": "Десна: [{{label}}]",
    "annotations": "Анотације",
    "attribution": "Приступ",
    "attributionTitle": "Права",
    "authenticationFailed": "Неуспела аутентификација.",
    "authenticationRequired": "За потпуни приступ неопходна је аутентификација",
    "backToResults": "Повратак на резултате",
    "book": "Књига",
    "bottom": "На дну",
    "cancel": "Одустаните",
    "canvasIndex": "Индекс",
    "changeTheme": "Промените тему",
    "clearSearch": "обришите",
    "close": "Затворите",
    "closeAddResourceForm": "Затворите",
    "closeAddResourceMenu": "Затворите листу ресурса",
    "closeCompanionWindow": "Затворите панел",
    "closeWindow": "Затворите приказ",
    "collapseSection": "Сакријте \"{{section}}\" секцију",
    "collapseSidePanel": "Сакријте",
    "collection": "Колекција",
    "itemList": "Листа страница",
    "continue": "Наставите",
    "copy": "Копирајте",
    "currentItem": "Тренутна страница",
    "currentItem_1/1": "Тренутна страница",
    "currentItem_1/2": "Лева",
    "currentItem_2/2": "Десна",
    "dark": "Тамна тема",
    "digitizedView": "Приказ дигиталног објекта",
    "dismiss": "Откажите",
    "highlightAllAnnotations": "Приказ свих",
    "displayNoAnnotations": "Без приказа",
    "downloadExport": "Извезите радно окружење",
    "downloadExportWorkspace": "Извезите радно окружење",
    "elastic": "Еластични",
    "elasticDescription": "Слободно померајте и одредите величину приказа у неограниченом радном простору. Прикази могу бити већи од радног простора.",
    "emptyResourceList": "Ваша листа ресурса је празна",
    "error": "Грешка",
    "errorDialogConfirm": "OK",
    "errorDialogTitle": "Дошло је до грешке",
    "exitFullScreen": "Изађите из приказа преко целог екрана",
    "expandSection": "Проширите \"{{section}}\" секцију",
    "expandSidePanel": "Прикажите",
    "exportCopied": "Конфигурација радног окружења је копирана у привремену меморију",
    "fetchManifest": "Додајте",
    "fullScreen": "Приказ у пуном екрану",
    "gallery": "Галерија",
    "hideZoomControls": "Сакријте контроле зума",
    "iiif_homepage": "О ресурсу",
    "iiif_manifest": "IIIF manifest",
    "iiif_renderings": "Додатни формати",
    "iiif_seeAlso": "Погледајте и",
    "import" : "Увезите",
    "importWorkspace": "Увезите радно окружење",
    "importWorkspaceHint": "Убаците конфигурацију Mirador 3 како би могли да је увезете",
    "item": "Страница: {{label}}",
    "jsError": "Технички детаљи",
    "jsStack": "{{ stack }}",
    "language": "Језик приказа",
    "layer_hide": "Сакријте слој",
    "layer_move": "Померите слој",
    "layer_opacity": "Видљивост слоја",
    "layer_show": "Приказ слоја",
    "layer_moveToTop": "Померите слој на врх",
    "layers": "Слојеви",
    "light": "Светла тема",
    "links": "Линкови",
    "listAllOpenWindows": "Идите на приказ",
    "login": "Улогујте се",
    "logout": "Излогујте се",
    "manifestError": "Ресурс се не може додати",
    "maximizeWindow": "Максималан приказ",
    "minimizeWindow": "Минималан приказ",
    "mirador": "Mirador",
    "miradorResources": "Mirador ресурси",
    "miradorViewer": "Mirador прегледач",
    "more": "још...",
    "moreResults": "Још резултата",
    "mosaic": "Мозаик",
    "mosaicDescription": "Померајте и одредите величину приказа који су у међусобној релацији, унутар видљивог оквира.",
    "moveCompanionWindowToBottom": "Пребаците на дно",
    "moveCompanionWindowToRight": "Померите у десну страну",
    "nextCanvas": "Следећа",
    "noItemSelected": "Нема изабраних објеката",
    "numItems": "{{number}} страница/це",
    "off": "Искључене",
    "openCompanionWindow_annotations": "Анотације",
    "openCompanionWindow_attribution": "Права",
    "openCompanionWindow_canvas": "Индекс",
    "openCompanionWindow_info": "Информације",
    "openCompanionWindow_layers": "Слојеви",
    "openCompanionWindow_search": "Претрага",
    "openInCompanionWindow": "Отворите у засебном панелу",
    "openWindows": "Тренутно отворени прикази",
    "pagination": "{{current}} од {{total}}",
    "position": "Позиција",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "Претходна",
    "related": "Релације",
    "resource": "Ресурс",
    "retry": "Покушајте поново",
    "right": "Десно",
    "rights": "Лиценца",
    "scroll": "Померајте",
    "searchInputLabel": "претражите појам",
    "searchNextResult": "Следећи резултат",
    "searchNoResults": "Тражени појам није пронађен",
    "searchPreviousResult": "Претходни резултат",
    "searchSubmitAria": "Претражите",
    "searchTitle": "Претрага",
    "selectWorkspaceMenu": "Изаберите тип радног окружења",
    "showingNumAnnotations": "Приказ {{number}} анотација/је",
    "showCollection": "Прикажи колекцију",
    "showZoomControls": "Приказ контрола зума",
    "sidebarPanelsNavigation": "Навигација сајдбар панела",
    "single": "Појединачно",
    "startHere": "Започните",
    "suggestSearch": "Пронађите појам \"{{ query }}\" у овом документу",
    "tableOfContentsList": "Садржај",
    "theme": "Тема",
    "thumbnailList": "Листа сличица",
    "thumbnailNavigation": "Сличице",
    "thumbnails": "Сличице",
    "toggleWindowSideBar": "Сајдбар",
    "totalCollections": "{{count}} колекција/е",
    "totalManifests": "{{count}} објек(а)та",
    "tryAgain": "Покушајте поново",
    "untitled": "[Без наслова]",
    "view": "Преглед",
    "welcome": "Добродошли у прегледач Mirador",
    "window": "Приказ: {{label}}",
    "windowMenu": "Изглед прегледа и позиција сличица",
    "windowNavigation": "Навигација приказа",
    "windowPluginButtons": "Опције",
    "windowPluginMenu": "Опције приказа",
    "workspace": "Радни простор",
    "workspaceFullScreen": "Приказ преко целог екрана",
    "workspaceMenu": "Уређивање радног простора",
    "workspaceOptions": "Опције радног окружења",
    "workspaceSelectionTitle": "Изаберите тип радног окружења",
    "zoomIn": "Увећајте",
    "zoomOut": "Умањите",
    "zoomReset": "Ресет зума"
  }
}
