{
  "translation": {
    "aboutMirador": "Om Projekt Mirador",
    "aboutThisItem": "Om dette objektet",
    "addedFromUrl": "(Lagt til fra URL)",
    "addManifestUrl": "Nettadresse til samling eller manifest",
    "addManifestUrlHelp": "URL til en IIIF-ressurs",
    "addResource": "Legg til ressurs",
    "annotationCanvasLabel_1/1": "Objekt: [{{label}}]",
    "annotationCanvasLabel_1/2": "Venstre: [{{label}}]",
    "annotationCanvasLabel_2/2": "Høyre: [{{label}}]",
    "annotations": "Annoteringer",
    "attribution": "Tilskrivelse",
    "attributionTitle": "Rettigheter",
    "authenticationFailed": "Autentiseringen feilet.",
    "authenticationRequired": "Autentisering kreves for full tilgang",
    "backToResults": "Tilbake til resultat",
    "book": "Bok",
    "bottom": "Nederst",
    "cancel": "Avbryt",
    "canvasIndex": "Index",
    "changeTheme": "Skift tema",
    "clearSearch": "Fjern søket",
    "close": "Lukk",
    "closeAddResourceForm": "Lukk skjemaet",
    "closeAddResourceMenu": "Lukk ressurslisten",
    "closeCompanionWindow": "Lukk panelet",
    "closeWindow": "Lukk vinduet",
    "collapseSection": "Lukk seksjonen \"{{section}}\"",
    "collapseSidePanel": "Lukk sidemenyen",
    "collection": "Samling",
    "itemList": "Objektliste",
    "continue": "Fortsett",
    "copy": "Kopiere",
    "currentItem": "Valgt objekt",
    "currentItem_1/1": "Valgt objekt",
    "currentItem_1/2": "Venstre",
    "currentItem_2/2": "Høyre",
    "dark": "Mørkt tema",
    "digitizedView": "Digitalisert visning",
    "dismiss": "Lukk",
    "highlightAllAnnotations": "Markér alle",
    "displayNoAnnotations": "Avmarkér alle",
    "downloadExport": "Eksportér arbeidsområde",
    "downloadExportWorkspace": "Eksportér arbeidsområde",
    "elastic": "Elastisk",
    "elasticDescription": "Flytt og endre størrelsen på vinduet fritt i et ubegrenset arbeidsområde. Vindu kan overlappe.",
    "emptyResourceList": "Din resursliste er tom",
    "error": "Error",
    "errorDialogConfirm": "OK",
    "errorDialogTitle": "Et problem oppstod",
    "exitFullScreen": "Forlat fullskjermsvisning",
    "expandSection": "Ekspandér seksjonen \"{{section}}\"",
    "expandSidePanel": "Ekspandér sidemenyen",
    "exportCopied": "Konfiguration av arbeidsområdet ble kopiert til din utklippstavle",
    "fetchManifest": "Legg til",
    "fullScreen": "Fullskjermsvisning",
    "gallery": "Galleri",
    "hideZoomControls": "Skjul zoomkontroll",
    "iiif_homepage": "Om denne ressursen",
    "iiif_manifest": "IIIF manifest",
    "iiif_renderings": "Alternativt format",
    "iiif_seeAlso": "Se også",
    "import": "Importér",
    "importWorkspace": "Importér arbeidsområde",
    "importWorkspaceHint": "Lim inn en Mirador 3 konfigurasjon for import",
    "item": "Objekt: {{label}}",
    "jsError": "Tekniske detaljer",
    "jsStack": "{{ stack }}",
    "language": "Språk",
    "layer_hide": "Skjul lag",
    "layer_move": "Flytt lag",
    "layer_opacity": "Lag-gjennomsiktighet",
    "layer_show": "Vis lag",
    "layer_moveToTop": "Flytt laget øverst",
    "layers": "Lag",
    "light": "Lyst tema",
    "links": "Lenker",
    "listAllOpenWindows": "Gå til vindu",
    "login": "Logg inn",
    "logout": "Logg ut",
    "manifestError": "Ressursen kan ikke legges til:",
    "maximizeWindow": "Maksimér vinduet",
    "minimizeWindow": "Minimér vinduet",
    "mirador": "Mirador",
    "miradorResources": "Miradorressurser",
    "miradorViewer": "Mirador bildeviser",
    "more": "mer...",
    "moreResults": "Flere resultat",
    "mosaic": "Mosaik",
    "mosaicDescription": "Flytt og endre størrelse på vinduet i relasjon til hverandre, innenfor den synlige rammen.",
    "moveCompanionWindowToBottom": "Flytt til bunnen",
    "moveCompanionWindowToRight": "Flytt til høyre",
    "nextCanvas": "Neste objekt",
    "noItemSelected": "Ingen valgte objekt",
    "numItems": "{{number}} objekt",
    "numItems_plural": "{{number}} objekter",
    "off": "Av",
    "openCompanionWindow_annotations": "Annoteringer",
    "openCompanionWindow_attribution": "Rettigheter",
    "openCompanionWindow_canvas": "Indeks",
    "openCompanionWindow_info": "Informasjon",
    "openCompanionWindow_layers": "Lag",
    "openCompanionWindow_search": "Søk",
    "openInCompanionWindow": "Åpne i eget panel",
    "openWindows": "Åpne vindu",
    "pagination": "{{current}} av {{total}}",
    "position": "Posisjon",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "Forrige objekt",
    "related": "Relatert",
    "resource": "Ressurs",
    "retry": "Forsøk igjen",
    "right": "Til høyre",
    "rights": "Lisens",
    "scroll": "Bla",
    "searchInputLabel": "Søkeord",
    "searchNextResult": "Neste resultat",
    "searchNoResults": "Ingen treff",
    "searchPreviousResult": "Forrige resultat",
    "searchResultsRemaining": "{{numLeft}} igjen",
    "searchSubmitAria": "Søk",
    "searchTitle": "Søk",
    "selectWorkspaceMenu": "Velg arbeidsområde-type",
    "showingNumAnnotations": "Vis {{number}} annotasjon",
    "showingNumAnnotations_plural": "Vis {{number}} annotasjoner",
    "showCollection": "Vis samling",
    "showZoomControls": "Vis zoomkontroll",
    "sidebarPanelsNavigation": "Sidemeny-panel navigering",
    "single": "En og en",
    "startHere": "Start her",
    "suggestSearch": "Søk etter \"{{ query }}\" i dette dokument",
    "tableOfContentsList": "Innholdsfortegnelse",
    "theme": "Tema",
    "thumbnailList": "Miniatyrliste",
    "thumbnailNavigation": "Miniatyrer",
    "thumbnails": "Miniatyrer",
    "toggleWindowSideBar": "Vis/skjul sidemenyen",
    "totalCollections": "{{count}} samling",
    "totalCollections_plural": "{{count}} samlinger",
    "totalManifests": "{{count}} manifest",
    "totalManifests_plural": "{{count}} manifester",
    "tryAgain": "Forsøk igjen",
    "untitled": "[uten tittel]",
    "view": "Visning",
    "viewWorkspaceConfiguration": "Vis konfigurasjon av arbeidsområde.",
    "welcome": "Velkommen til Mirador",
    "window": "Vindu: {{label}}",
    "windowMenu": "Vindusvisning & miniatyrvisning",
    "windowNavigation": "Vindusnavigasjon",
    "windowPluginButtons": "Innstillinger",
    "windowPluginMenu": "Vindusinnstillinger",
    "workspace": "Arbeidsområde",
    "workspaceNavigation": "Navigasjon i arbeidsområdet",
    "workspaceFullScreen": "Fullskjermsvisning",
    "workspaceMenu": "Innstillinger for arbeidsområdet",
    "workspaceOptions": "Flere valg for arbeidsområdet",
    "workspaceSelectionTitle": "Velg arbeidsområde-type",
    "zoomIn": "Zoom inn",
    "zoomOut": "Zoom ut",
    "zoomReset": "Tilbakestill zoom"
  }
}
