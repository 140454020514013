{
  "translation": {
    "aboutThisItem": "عن هذا العنصر",
    "addedFromUrl": "(تمت إضافته من الرابط)",
    "addManifestUrl": "موقع المصدر",
    "addManifestUrlHelp": "الرابط لمصدر IIIF ",
    "addResource": "اضف مصدر",
    "annotationCanvasLabel_1/1": "العنصر: [{{الملصق}}]",
    "annotationCanvasLabel_1/2": "يسار: [{{الملصق }}]",
    "annotationCanvasLabel_2/2": "يمين: [{{الملصق}}]",
    "annotations": "ملاحظات",
    "attribution": "إسناد",
    "attributionTitle": "حقوق",
    "authenticationFailed": "التوثيق لم ينجح",
    "authenticationRequired": "يجب توثيق الحساب لمنح الدخول الكامل",
    "backToResults": "الرجوع الى النتائج",
    "book": "كتاب",
    "bottom": "أسفل",
    "cancel": "الغاء",
    "canvasIndex": "فهرس",
    "changeTheme": "قم بتغيير الموضوع",
    "clearSearch": "امسح",
    "closeAddResourceForm": "إغلاق الإستمارة",
    "closeAddResourceMenu": "إغلاق قائمة المصادر",
    "closeCompanionWindow": "إغلاق اللوحة",
    "closeWindow": "إغلاق النافذة",
    "collapseSection": "تصغير جزء {{section}}",
    "collapseSidePanel": "تصغير الشريط الجانبي",
    "itemList": "قائمة العناصر",
    "continue": "استمر",
    "copy": "نسخ",
    "currentItem": "العنصر الحالي",
    "currentItem_1/1": "العنصر الحالي",
    "currentItem_1/2": "يسار",
    "currentItem_2/2": "يمين",
    "dark": "موضوع داكن",
    "dismiss": "تجاهل",
    "highlightAllAnnotations": "تحديد الكل",
    "downloadExport": "تصدير مساحة العمل",
    "downloadExportWorkspace": "تصدير مساحة العمل",
    "elastic": "مرن",
    "elasticDescription": "حرك النوافذ و غير حجمها بحرية في مساحة عمل غير محدودة. يمكن أن تتداخل النوافذ أيضاً .",
    "emptyResourceList": "قائمة المصادر الخاصة بك فارغة.",
    "errorDialogConfirm": "حسناً",
    "errorDialogTitle": "لقد حدث خطأ",
    "exitFullScreen": "خروج من الشاشة الكاملة",
    "expandSection": "توسيع الجزء {{section}}",
    "expandSidePanel": "توسيع الشريط الجانبي",
    "fetchManifest": "أضف",
    "fullScreen": "شاشة كاملة",
    "gallery": "المعرض",
    "hideZoomControls": "إخفاء ضوابط التكبير",
    "iiif_homepage": "عن هذه المصدر",
    "iiif_manifest": "IIIF قائمة ",
    "iiif_renderings": "تنسيقات بديلة",
    "iiif_seeAlso": "أنظر أيضا",
    "import" : "استورد ",
    "importWorkspace": "استيراد مساحة العمل",
    "importWorkspaceHint": "قم بلصق تركيبة Mirador 3 ليتم استيراده",
    "item": "العنصر: {{label}}",
    "language": "اللغة",
    "layer_hide": "إخفاء الطبقة",
    "layer_move": "تحريك الطبقة",
    "layer_opacity": "تعتيم الطبقة",
    "layer_show": "إظهار الطبقة",
    "layer_moveToTop": "حرك الطبقة إلى الأعلى",
    "layers": "طبقات",
    "light": "موضوع فاتح",
    "links": "الروابط",
    "listAllOpenWindows": "انتقل بسرعة للنافذة",
    "login": "تسجيل الدخول",
    "logout": "تسجيل الخروج",
    "manifestError": "لا يمكن إضافة المصدر:",
    "maximizeWindow": "تكبير النافذة إلى الحد الاكبر",
    "minimizeWindow": "تصغير النافذة إلى الحد الأدنى",
    "mirador": "Mirador",
    "miradorResources": "مصادر Mirador ",
    "miradorViewer": "مشاهد Mirador",
    "more": "أكثر...",
    "moreResults": "نتائج إضافية",
    "mosaic": "فسيفساء",
    "mosaicDescription": "حرك وغيٌر حجم النوافذ المتعلقة ببعضها البعض داخل الإطار المرئي.",
    "moveCompanionWindowToBottom": "انتقل للأسفل",
    "moveCompanionWindowToRight": "انتقل لليمين",
    "nextCanvas": "العنصر التالي",
    "numItems": "{{number}} عناصر",
    "off": "ايقاف",
    "openCompanionWindow_annotations": "ملاحظات",
    "openCompanionWindow_attribution": "حقوق",
    "openCompanionWindow_canvas": "فهرس",
    "openCompanionWindow_info": "معلومات",
    "openCompanionWindow_layers": "طبقات",
    "openCompanionWindow_search": "بحث",
    "openInCompanionWindow": "افتح في صفحة جديدة",
    "openWindows": "النوافذ الحالية المفتوحة",
    "pagination": "{{current}} من {{total}}",
    "position": "مركز",
    "previewWindowTitle": "عنوان",
    "previousCanvas": "العنصر السابق",
    "related": "ذات صلة",
    "resource": "مصدر",
    "retry": "أعد المحاولة ",
    "right": "حق",
    "rights": "ترخيص",
    "searchInputLabel": "مصطلحات البحث",
    "searchNextResult": "النتيجة التالية",
    "searchNoResults": "لم يتم العثور على نتائج",
    "searchPreviousResult": "النتيجة السابقة",
    "searchSubmitAria": "ابحث",
    "searchTitle": "بحث",
    "selectWorkspaceMenu": "اختر نوع مساحة العمل",
    "showingNumAnnotations": "تظهر {{number}} ملاحظات",
    "showZoomControls": "اظهر عناصر التحكم بالتكبير و التصغير",
    "sidebarPanelsNavigation": "تصفح لوح الشريط الجانبي",
    "single": "مفرد",
    "startHere": "إبدأ هنا",
    "suggestSearch": "ابحث في هذا المستند عن  \"{{ query }}\"",
    "tableOfContentsList": "جدول المحتويات",
    "theme": "موضوع",
    "thumbnailList": "قائمة الصور المصغرة",
    "thumbnailNavigation": "الصور المصغرة",
    "thumbnails": "الصور المصغرة",
    "toggleWindowSideBar": "تبديل الشريط الجانبي",
    "tryAgain": "حاول مرة أخرى",
    "untitled": "[بدون عنوان]",
    "view": "شاهد",
    "welcome": "مرحباً بكم في Mirador",
    "window": "النافذة:  {{label}}",
    "windowMenu": "عرض النوافذ و الصور المصغرة",
    "windowNavigation": "تصفح النافذة",
    "windowPluginButtons": "خيارات",
    "windowPluginMenu": "خيارات النافذة",
    "workspace": "مساحة العمل",
    "workspaceFullScreen": "شاشة كاملة",
    "workspaceMenu": "اعدادات مساحة العمل",
    "workspaceOptions": "خيارات مساحة العمل ",
    "workspaceSelectionTitle": "اختر نوع مساحة العمل",
    "zoomIn": "تكبير الصورة",
    "zoomOut": "تصغير الصورة",
    "zoomReset": "الرجوع لحجم الصورة الأساسي"
  }
}
