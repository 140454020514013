{
  "translation": {
    "aboutMirador": "O Projekcie Mirador",
    "aboutThisItem": "O bieżącej pozycji",
    "addedFromUrl": "(Dodano z URL)",
    "addManifestUrl": "Lokalizacja zasobów",
    "addManifestUrlHelp": "URL zasobów IIIF",
    "addResource": "Dodaj zasoby",
    "annotationCanvasLabel_1/1": "Pozycja: [{{label}}]",
    "annotationCanvasLabel_1/2": "Lewo: [{{label}}]",
    "annotationCanvasLabel_2/2": "Prawo: [{{label}}]",
    "annotations": "Adnotacje",
    "attribution": "Atrybucja",
    "attributionTitle": "Prawa",
    "authenticationFailed": "Uwierzytelnianie nie powiodło się.",
    "authenticationRequired": "Do pełnego dostępu wymagane jest uwierzytelnienie",
    "backToResults": "Powrót do wyników",
    "book": "Książka",
    "bottom": "Dół",
    "cancel": "Anuluj",
    "canvasIndex": "Indeks",
    "changeTheme": "Zmień motyw",
    "clearSearch": "wyczyść",
    "close": "Zamknij",
    "closeAddResourceForm": "Zamknij formularz",
    "closeAddResourceMenu": "Zamknij listę zasobów",
    "closeCompanionWindow": "Zamknij panel",
    "closeWindow": "Zamknij okno",
    "collapseSection": "Zwiń sekcję \"{{section}}\"",
    "collapseSidePanel": "Zwiń panel boczny",
    "collection": "Zbiór",
    "itemList": "Lista pozycji",
    "continue": "Kontynuuj",
    "copy": "Kopiuj",
    "currentItem": "Bieżąca pozycja",
    "currentItem_1/1": "Bieżąca pozycja",
    "currentItem_1/2": "Lewo",
    "currentItem_2/2": "Prawo",
    "dark": "Ciemny motyw",
    "digitizedView": "Widok zdigitalizowany",
    "dismiss": "Odrzuć",
    "highlightAllAnnotations": "Podświetl wszystko",
    "displayNoAnnotations": "Nie podświetlaj",
    "downloadExport": "Eksportuj obszar roboczy",
    "downloadExportWorkspace": "Eksportuj obszar roboczy",
    "elastic": "Elastyczny",
    "elasticDescription": "Swobodnie przesuwaj i dopasowuj okna w nieograniczonej przestrzeni roboczej. Okna mogą zachodzić na siebie.",
    "emptyResourceList": "Twoja lista zasobów jest pusta",
    "error": "Błąd",
    "errorDialogConfirm": "OK",
    "errorDialogTitle": "Wystąpił błąd",
    "exitFullScreen": "Wyłącz tryb pełnoekranowy",
    "expandSection": "Rozwiń sekcję \"{{section}}\"",
    "expandSidePanel": "Rozwiń panel boczny",
    "exportCopied": "Konfiguracja obszaru roboczego została skopiowana do schowka",
    "fetchManifest": "Dodaj",
    "fullScreen": "Pełny ekran",
    "gallery": "Galeria",
    "hideZoomControls": "Ukryj kontrolki powiększenia",
    "iiif_homepage": "O tym zasobie",
    "iiif_manifest": "Manifest IIIF",
    "iiif_renderings": "Alternatywne formaty",
    "iiif_seeAlso": "Zobacz też",
    "import" : "Importuj",
    "importWorkspace": "Importuj obszar roboczy",
    "importWorkspaceHint": "Wklej konfigurację Mirador 3 do zaimportowania",
    "item": "Pozycja: {{label}}",
    "jsError": "Szczegóły techniczne",
    "jsStack": "{{ stack }}",
    "language": "Język",
    "layer_hide": "Ukryj warstwę",
    "layer_move": "Przenieś warstwę",
    "layer_opacity": "Krycie warstwy",
    "layer_show": "Pokaż warstwę",
    "layer_moveToTop": "Przenieś warstwę na górę",
    "layers": "Warstwy",
    "light": "Jasny motyw",
    "links": "Linki",
    "listAllOpenWindows": "Przejdź do okna",
    "login": "Zaloguj",
    "logout": "Wyloguj",
    "manifestError": "Następujące zasoby nie mogą być dodane:",
    "maximizeWindow": "Maksymalizuj okno",
    "minimizeWindow": "Minimalizuj okno",
    "mirador": "Mirador",
    "miradorResources": "Zasoby Mirador",
    "miradorViewer": "Przeglądarka Mirador",
    "more": "więcej...",
    "moreResults": "Więcej wyników",
    "mosaic": "Mozaika",
    "mosaicDescription": "Przesuwaj i zmieniaj rozmiary okien względem siebie, w widocznej ramce.",
    "moveCompanionWindowToBottom": "Przesuń na dół",
    "moveCompanionWindowToRight": "Przesuń w prawo",
    "nextCanvas": "Następna pozycja",
    "noItemSelected": "Nie wybrano pozycji",
    "numItems": "{{number}} pozycja",
    "numItems_plural": "{{number}} pozycje",
    "off": "Wyłącz",
    "openCompanionWindow_annotations": "Adnotacje",
    "openCompanionWindow_attribution": "Prawa",
    "openCompanionWindow_canvas": "Indeks",
    "openCompanionWindow_info": "Informacje",
    "openCompanionWindow_layers": "Warstwy",
    "openCompanionWindow_search": "Szukaj",
    "openInCompanionWindow": "Otwórz w oddzielnym panelu",
    "openWindows": "Aktualnie otwarte okna",
    "pagination": "{{current}} of {{total}}",
    "position": "Pozycja",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "Poprzednia pozycja",
    "related": "Powiązane",
    "resource": "Zasoby",
    "retry": "Spróbuj ponownie",
    "right": "Prawo",
    "rights": "Licencja",
    "scroll": "Przewiń",
    "searchInputLabel": "szukane słowa",
    "searchNextResult": "Następny wynik",
    "searchNoResults": "Nie znaleziono wyników",
    "searchPreviousResult": "Poprzedni wynik",
    "searchResultsRemaining": "Pozostało {{numLeft}}",
    "searchSubmitAria": "Wyszukaj",
    "searchTitle": "Wyszukaj",
    "selectWorkspaceMenu": "Wybierz typ obszaru roboczego",
    "showingNumAnnotations": "Wyświetlanie {{number}} adnotacji",
    "showingNumAnnotations_plural": "Wyświetlanie {{number}} adnotacji",
    "showCollection": "Pokaż zbiór",
    "showZoomControls": "Pokaż kontrolki powiększenia",
    "sidebarPanelsNavigation": "Nawigacja pasków panelu bocznego",
    "single": "Pojedynczy",
    "startHere": "Zacznij tutaj",
    "suggestSearch": "Przeszukaj ten dokument pod kątem \"{{ query }}\"",
    "tableOfContentsList": "Spis treści",
    "theme": "Motyw",
    "thumbnailList": "Lista miniatur",
    "thumbnailNavigation": "Miniatury",
    "thumbnails": "Miniatury",
    "toggleWindowSideBar": "Przełącz panel boczny",
    "totalCollections": "{{count}} zbiór",
    "totalCollections_plural": "{{count}} zbiorów",
    "totalManifests": "{{count}} manifest",
    "totalManifests_plural": "{{count}} manifestów",
    "tryAgain": "Spróbuj ponownie",
    "untitled": "[Bez nazwy]",
    "view": "Widok",
    "viewWorkspaceConfiguration": "Wyświetl konfigurację obszaru roboczego",
    "welcome": "Witaj w Miradorze",
    "window": "Okno: {{label}}",
    "windowMenu": "Widoki okien i wyświetlanie miniatur",
    "windowNavigation": "Nawigacja okna",
    "windowPluginButtons": "Opcje",
    "windowPluginMenu": "Opcje okna",
    "workspace": "Obszar roboczy",
    "workspaceNavigation": "Nawigacja obszaru roboczego",
    "workspaceFullScreen": "Pełny ekran",
    "workspaceMenu": "Ustawienia obszaru roboczego",
    "workspaceOptions": "Opcje obszaru roboczego",
    "workspaceSelectionTitle": "Wybierz typ obszaru roboczego",
    "zoomIn": "Przybliż",
    "zoomOut": "Oddal",
    "zoomReset": "Zresetuj powiększenie"
  }
}
