{
  "translation": {
    "aboutMirador": "Über Mirador",
    "aboutThisItem": "Über dieses Element",
    "addedFromUrl": "(Via URL hinzugefügt)",
    "addManifestUrl": "Ort der Ressource",
    "addManifestUrlHelp": "Die URL einer IIIF-Ressource",
    "addResource": "Ressource hinzufügen",
    "annotationCanvasLabel_1/1": "Objekt: [{{label}}]",
    "annotationCanvasLabel_1/2": "Links: [{{label}}]",
    "annotationCanvasLabel_2/2": "Rechts: [{{label}}]",
    "annotations": "Annotationen",
    "attribution": "Zuschreibung",
    "attributionTitle": "Rechte",
    "authenticationFailed": "Authentifizierung fehlgeschlagen",
    "authenticationRequired": "Für vollen Zugriff ist eine Authentifizierung nötig.",
    "backToResults": "Zurück zur Trefferansicht",
    "book": "Buch",
    "bottom": "Unten",
    "cancel": "Abbrechen",
    "canvasIndex": "Index",
    "changeTheme": "Farbschema ändern",
    "clearSearch": "zurücksetzen",
    "closeAddResourceForm": "Formular schließen",
    "closeAddResourceMenu": "Ressourcenliste schließen",
    "closeCompanionWindow": "Hilfsfenster schließen",
    "closeWindow": "Fenster schließen",
    "collapseSection": "Bereich \"{{section}}\" zuklappen",
    "collapseSidePanel": "Seitenleiste zuklappen",
    "itemList": "Kompaktliste",
    "continue": "Fortfahren",
    "copy": "Kopieren",
    "currentItem": "Aktuelles Objekt",
    "currentItem_1/1": "Aktuelles Objekt",
    "currentItem_1/2": "Links",
    "currentItem_2/2": "Rechts",
    "dark": "Dunkel",
    "digitizedView": "Digitalisat-Ansicht",
    "dismiss": "Verwerfen",
    "highlightAllAnnotations": "Alle hervorheben",
    "displayNoAnnotations": "Keine hervorheben",
    "downloadExport": "Arbeitsfläche exportieren",
    "downloadExportWorkspace": "Arbeitsfläche exportieren",
    "elastic": "Elastisch",
    "elasticDescription": "Fenster können frei und unbegrenzt innerhalb der Arbeitsfläche bewegt werden und sich überlappen",
    "emptyResourceList": "Ihre Ressourcenliste ist leer",
    "error": "Fehler",
    "errorDialogConfirm": "OK",
    "errorDialogTitle": "Es ist ein Fehler aufgetreten",
    "exitFullScreen": "Vollbildmodus verlassen",
    "expandSection": "Bereich \"{{section}}\" aufklappen",
    "expandSidePanel": "Seitenleiste aufklappen",
    "exportCopied": "Die Konfiguration der Arbeitsfläche wurde in die Zwischenablage kopiert.",
    "fetchManifest": "Hinzufügen",
    "fullScreen": "Vollbild",
    "gallery": "Galerie",
    "hideZoomControls": "Zoomsteuerung verbergen",
    "iiif_homepage": "Über diese Ressource",
    "iiif_manifest": "IIIF-Manifest",
    "iiif_renderings": "Alternative Formate",
    "iiif_seeAlso": "Siehe auch",
    "import" : "Importieren",
    "importWorkspace": "Arbeitsfläche importieren",
    "importWorkspaceHint": "Fügen Sie eine Mirador-3-Konfiguration ein, die importiert werden soll.",
    "item": "Objekt: {{label}}",
    "jsError": "Technische Details",
    "jsStack": "{{ stack }}",
    "language": "Sprache",
    "layer_hide": "Ebene verbergen",
    "layer_move": "Ebene verschieben",
    "layer_opacity": "Ebenendeckkraft",
    "layer_show": "Ebene anzeigen",
    "layer_moveToTop": "Ebene ganz nach vorn bringen",
    "layers": "Ebenen",
    "light": "Hell",
    "links": "Links",
    "listAllOpenWindows": "Zu Fenster springen",
    "login": "Anmelden",
    "logout": "Abmelden",
    "manifestError": "Die Ressource konnte nicht hinzugefügt werden:",
    "maximizeWindow": "Fenster maximieren",
    "minimizeWindow": "Fenster minimieren",
    "mirador": "Mirador",
    "miradorResources": "Mirador-Ressourcen",
    "miradorViewer": "Mirador",
    "more": "mehr...",
    "moreResults": "Mehr Treffer",
    "mosaic": "Mosaik",
    "mosaicDescription": "Fenster können innerhalb der Arbeitsfläche in Relation zueinander verschoben und ihrer Größe verändert werden.",
    "moveCompanionWindowToBottom": "Unten anordnen",
    "moveCompanionWindowToRight": "Rechts anordnen",
    "nextCanvas": "Nächstes Objekt",
    "numItems": "{{number}} Elemente",
    "off": "Keine",
    "openCompanionWindow_annotations": "Annotationen",
    "openCompanionWindow_attribution": "Rechte",
    "openCompanionWindow_canvas": "Index",
    "openCompanionWindow_info": "Informationen",
    "openCompanionWindow_layers": "Ebenen",
    "openCompanionWindow_search": "Suche",
    "openInCompanionWindow": "In Hilfsfenster öffnen",
    "openWindows": "Aktuell offene Fenster",
    "pagination": "{{current}} von {{total}}",
    "position": "Position",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "Vorheriges Objekt",
    "related": "Verwandtes",
    "resource": "Ressource",
    "retry": "Erneut versuchen",
    "right": "Rechts",
    "rights": "Lizenz",
    "scroll": "Kontinuierlich",
    "searchInputLabel": "Suchbegriffe",
    "searchNextResult": "Nächster Treffer",
    "searchNoResults": "Keine Treffer",
    "searchPreviousResult": "Vorheriger Treffer",
    "searchResultsRemaining": "{{numLeft}} weitere",
    "searchSubmitAria": "Suchen",
    "searchTitle": "Suche",
    "selectWorkspaceMenu": "Wählen Sie einen Arbeitsflächentyp",
    "showingNumAnnotations": "{{number}} Annotationen werden angezeigt",
    "showZoomControls": "Zoomsteuerung anzeigen",
    "sidebarPanelsNavigation": "Hilfsfensternavigation",
    "single": "Einzeln",
    "startHere": "Hier starten",
    "suggestSearch": "Dieses Dokument durchsuchen nach \"{{ query }}\"",
    "tableOfContentsList": "Inhaltsverzeichnis",
    "theme": "Farbschema",
    "thumbnailList": "Miniaturansicht",
    "thumbnailNavigation": "Miniaturansicht",
    "thumbnails": "Miniaturansicht",
    "toggleWindowSideBar": "Seitenleiste umschalten",
    "tryAgain": "Wiederholen",
    "untitled": "[Unbenannt]",
    "view": "Ansicht",
    "welcome": "Willkommen bei Mirador",
    "window": "Fenster: {{label}}",
    "windowMenu": "Fenstermenü und Miniaturansicht",
    "windowNavigation": "Fensternavigation",
    "windowPluginButtons": "Optionen",
    "windowPluginMenu": "Fensteroptionen",
    "workspace": "Arbeitsfläche",
    "workspaceNavigation": "Arbeitsflächensteuerung",
    "workspaceFullScreen": "Vollbild",
    "workspaceMenu": "Arbeitsflächenmenü",
    "workspaceOptions": "Arbeitsflächenoptionen",
    "workspaceSelectionTitle": "Bitte wählen Sie einen Arbeitsflächentyp",
    "zoomIn": "Vergrößern",
    "zoomOut": "Verkleinern",
    "zoomReset": "Ansicht zurücksetzen"
  }
}
