{
  "translation": {
    "aboutThisItem": "Over dit item",
    "addedFromUrl": "(Toegevoegd via URL)",
    "addManifestUrl": "Locatie bron",
    "addManifestUrlHelp": "De URL van een IIIF-resource",
    "addResource": "Voeg bron toe",
    "annotationCanvasLabel_1/1": "Item: [{{label}}]",
    "annotationCanvasLabel_1/2": "Links: [{{label}}]",
    "annotationCanvasLabel_2/2": "Rechts: [{{label}}]",
    "annotations": "Annotaties",
    "attribution": "Naamsvermelding",
    "attributionTitle": "Rechten",
    "authenticationFailed": "Authenticatie gefaald.",
    "authenticationRequired": "Authenticatie is vereist voor volledige toegang",
    "backToResults": "Terug naar resultaten",
    "book": "Boek",
    "bottom": "Onderaan",
    "cancel": "Annuleer",
    "canvasIndex": "Index",
    "changeTheme": "Wijzig thema",
    "clearSearch": "clear",
    "closeAddResourceForm": "Sluit formulier",
    "closeAddResourceMenu": "Sluit lijst met bronnen",
    "closeCompanionWindow": "Sluit paneel",
    "closeWindow": "Sluit venster",
    "collapseSection": "Klap \"{{section}}\" sectie in",
    "collapseSidePanel": "Klap zijbalk in",
    "itemList": "Compacte lijst",
    "continue": "Ga verder",
    "copy": "Kopieer",
    "currentItem": "Huidig item",
    "currentItem_1/1": "Huidig item",
    "currentItem_1/2": "Links",
    "currentItem_2/2": "Rechts",
    "dark": "Donker thema",
    "dismiss": "Dismiss",
    "highlightAllAnnotations": "Markeer alles",
    "downloadExport": "Exporteer workspace",
    "downloadExportWorkspace": "Exporteer workspace",
    "elastic": "Elastisch",
    "elasticDescription": "Verplaats en rangschik vensters vrij in een onbeperkte workspace. Vensters kunnen elkaar overlappen.",
    "emptyResourceList": "Je lijst met bronnen is leeg",
    "errorDialogConfirm": "OK",
    "errorDialogTitle": "Er is een fout opgetreden",
    "exitFullScreen": "Verlaat volledig scherm",
    "expandSection": "Klap \"{{section}}\" sectie uit",
    "expandSidePanel": "Klap zijbalk uit",
    "fetchManifest": "Voeg toe",
    "fullScreen": "Volledig scherm",
    "gallery": "Gallerij",
    "hideZoomControls": "Verberg zoomknoppen",
    "iiif_homepage": "Over deze bron",
    "iiif_manifest": "IIIF-manifest",
    "iiif_renderings": "Alternatieve formaten",
    "iiif_seeAlso": "Zie ook",
    "import" : "Importeer",
    "importWorkspace": "Importeer workspace",
    "importWorkspaceHint": "Plak een Mirador 3 configuratie die geïmporteerd moet worden",
    "item": "Item: {{label}}",
    "language": "Taal",
    "light": "Licht thema",
    "links": "Links",
    "listAllOpenWindows": "Ga naar venster",
    "login": "Log in",
    "manifestError": "De bron kan niet worden toegevoegd:",
    "maximizeWindow": "Maximaliseer venster",
    "minimizeWindow": "Minimaliseer venster",
    "mirador": "Mirador",
    "miradorResources": "Mirador resources",
    "miradorViewer": "Mirador viewer",
    "more": "meer...",
    "moreResults": "Meer resultaten",
    "mosaic": "Mozaïek",
    "mosaicDescription": "Verplaats en rangschik vensters in verhouding tot elkaar binnen het zichtbare kader.",
    "moveCompanionWindowToBottom": "Verplaats naar beneden",
    "moveCompanionWindowToRight": "Verplaats naar rechts",
    "nextCanvas": "Volgend item",
    "numItems": "{{number}} item",
    "numItems_plural": "{{number}} items", 
    "off": "Uit",
    "openCompanionWindow_annotations": "Annotaties",
    "openCompanionWindow_attribution": "Rechten",
    "openCompanionWindow_canvas": "Index",
    "openCompanionWindow_info": "Informatie",
    "openCompanionWindow_search": "Zoeken",
    "openInCompanionWindow": "Open in een apart paneel",
    "openWindows": "Geopende vensters",
    "pagination": "{{current}} van {{total}}",
    "position": "Positie",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "Vorig item",
    "related": "Verwant",
    "resource": "Bron",
    "retry": "Probeer opnieuw",
    "right": "Rechts",
    "rights": "Licentie",
    "searchInputLabel": "zoek termen",
    "searchNextResult": "Volgend resultaat",
    "searchNoResults": "Geen resultaten gevonden",
    "searchPreviousResult": "Vorig resultaat",
    "searchSubmitAria": "Zoeken",
    "searchTitle": "Zoek",
    "selectWorkspaceMenu": "Selecteer workspacetype",
    "showingNumAnnotations": "{{number}} annotatie weergegeven",
    "showingNumAnnotations_plural": "{{number}} annotaties weergegeven",
    "showZoomControls": "Toon zoomknoppen",
    "sidebarPanelsNavigation": "Zijbalk panelen navigatie",
    "single": "Enkel",
    "startHere": "Begin Hier",
    "suggestSearch": "Zoek in dit document naar \"{{ query }}\"",
    "theme": "Thema",
    "thumbnailList": "Lijst thumbnails",
    "thumbnailNavigation": "Thumbnails",
    "thumbnails": "Thumbnails",
    "toggleWindowSideBar": "Toon zijbalk",
    "totalCollections": "{{count}} collectie",
    "totalCollections_plural": "{{count}} collecties",
    "totalManifests": "{{count}} manifest",
    "totalManifests_plural": "{{count}} manifests",
    "tryAgain": "Probeer opnieuw",
    "untitled": "[Zonder titel]",
    "view": "Weergave",
    "welcome": "Welkom in Mirador",
    "window": "Venster: {{label}}",
    "windowMenu": "Vensters- en miniatuurweergave",
    "windowNavigation": "Navigatie venster",
    "windowPluginButtons": "Opties",
    "windowPluginMenu": "Vensteropties",
    "workspace": "Workspace",
    "workspaceFullScreen": "Volledig scherm",
    "workspaceMenu": "Workspace instellingen",
    "workspaceOptions": "Workspace opties",
    "workspaceSelectionTitle": "Selecteer een workspace type",
    "zoomIn": "Zoom in",
    "zoomOut": "Zoom out",
    "zoomReset": "Reset zoom"
  }
}
