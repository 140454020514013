import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TuneSharpIcon from '@material-ui/icons/TuneSharp';

var MiradorImageToolsMenuItem = function MiradorImageToolsMenuItem(_ref) {
  var enabled = _ref.enabled,
      handleClose = _ref.handleClose,
      t = _ref.t,
      updateWindow = _ref.updateWindow,
      windowId = _ref.windowId;

  var handleClickOpen = function handleClickOpen() {
    handleClose();
    updateWindow(windowId, { imageToolsEnabled: !enabled });
  };

  return React.createElement(
    MenuItem,
    { onClick: handleClickOpen },
    React.createElement(
      ListItemIcon,
      null,
      React.createElement(TuneSharpIcon, null)
    ),
    React.createElement(
      ListItemText,
      { primaryTypographyProps: { variant: 'body1' } },
      enabled ? t('hide') : t('show')
    )
  );
};

MiradorImageToolsMenuItem.propTypes = process.env.NODE_ENV !== "production" ? {
  enabled: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateWindow: PropTypes.func.isRequired,
  windowId: PropTypes.string.isRequired
} : {};

MiradorImageToolsMenuItem.defaultProps = {
  enabled: true
};

export default MiradorImageToolsMenuItem;