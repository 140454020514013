function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash/flowRight';
import BrightnessIcon from '@material-ui/icons/Brightness5';
import TonalityIcon from '@material-ui/icons/Tonality';
import GradientIcon from '@material-ui/icons/Gradient';
import ContrastIcon from '@material-ui/icons/ExposureSharp';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import TuneSharpIcon from '@material-ui/icons/TuneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import ReplaySharpIcon from '@material-ui/icons/ReplaySharp';
import { fade } from '@material-ui/core/styles/colorManipulator';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';
import { MiradorMenuButton } from 'mirador/dist/es/src/components/MiradorMenuButton';
import ImageTool from './ImageTool';
import ImageRotation from './ImageRotation';
import ImageFlip from './ImageFlip';

/** Styles for withStyles HOC */
var styles = function styles(_ref) {
  var _root, _borderContainer;

  var breakpoints = _ref.breakpoints,
      palette = _ref.palette;

  var backgroundColor = palette.shades.main;
  var foregroundColor = palette.getContrastText(backgroundColor);
  var border = '1px solid ' + fade(foregroundColor, 0.2);
  var borderImageRight = 'linear-gradient(' + 'to bottom, ' + (fade(foregroundColor, 0) + ' 20%, ') + (fade(foregroundColor, 0.2) + ' 20% 80%, ') + (fade(foregroundColor, 0) + ' 80% )');
  var borderImageBottom = borderImageRight.replace('to bottom', 'to right');
  return {
    root: (_root = {
      backgroundColor: fade(backgroundColor, 0.8),
      borderRadius: 25,
      position: 'absolute',
      top: 8,
      right: 8,
      zIndex: 999,
      display: 'flex',
      flexDirection: 'row'
    }, _root[breakpoints.down('sm')] = {
      flexDirection: 'column'
    }, _root),
    borderContainer: (_borderContainer = {
      border: 0,
      borderRight: border,
      borderImageSlice: 1,
      borderImageSource: borderImageRight,
      display: 'flex',
      flexDirection: 'row'
    }, _borderContainer[breakpoints.down('sm')] = {
      flexDirection: 'column',
      borderBottom: border,
      borderRight: 'none',
      borderImageSource: borderImageBottom
    }, _borderContainer)
  };
};

var MiradorImageTools = function (_Component) {
  _inherits(MiradorImageTools, _Component);

  function MiradorImageTools(props) {
    _classCallCheck(this, MiradorImageTools);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.toggleState = _this.toggleState.bind(_this);
    _this.toggleRotate = _this.toggleRotate.bind(_this);
    _this.toggleFlip = _this.toggleFlip.bind(_this);
    _this.handleChange = _this.handleChange.bind(_this);
    _this.handleReset = _this.handleReset.bind(_this);
    return _this;
  }

  MiradorImageTools.prototype.componentDidMount = function componentDidMount() {
    var viewer = this.props.viewer;

    if (viewer) this.applyFilters();
  };

  MiradorImageTools.prototype.componentDidUpdate = function componentDidUpdate(prevProps) {
    var _props = this.props,
        viewConfig = _props.viewConfig,
        viewer = _props.viewer;

    if (viewer && viewConfig !== prevProps.viewConfig) this.applyFilters();
  };

  MiradorImageTools.prototype.handleChange = function handleChange(param) {
    var _props2 = this.props,
        updateViewport = _props2.updateViewport,
        windowId = _props2.windowId;

    return function (value) {
      var _updateViewport;

      return updateViewport(windowId, (_updateViewport = {}, _updateViewport[param] = value, _updateViewport));
    };
  };

  MiradorImageTools.prototype.handleReset = function handleReset() {
    var _props3 = this.props,
        updateViewport = _props3.updateViewport,
        windowId = _props3.windowId;

    var viewConfig = {
      rotation: 0,
      flip: false,
      brightness: 100,
      contrast: 100,
      saturate: 100,
      grayscale: 0,
      invert: 0
    };

    updateViewport(windowId, viewConfig);
  };

  MiradorImageTools.prototype.applyFilters = function applyFilters() {
    var _props4 = this.props,
        _props4$viewConfig = _props4.viewConfig,
        _props4$viewConfig$br = _props4$viewConfig.brightness,
        brightness = _props4$viewConfig$br === undefined ? 100 : _props4$viewConfig$br,
        _props4$viewConfig$co = _props4$viewConfig.contrast,
        contrast = _props4$viewConfig$co === undefined ? 100 : _props4$viewConfig$co,
        _props4$viewConfig$sa = _props4$viewConfig.saturate,
        saturate = _props4$viewConfig$sa === undefined ? 100 : _props4$viewConfig$sa,
        _props4$viewConfig$gr = _props4$viewConfig.grayscale,
        grayscale = _props4$viewConfig$gr === undefined ? 0 : _props4$viewConfig$gr,
        _props4$viewConfig$in = _props4$viewConfig.invert,
        invert = _props4$viewConfig$in === undefined ? 0 : _props4$viewConfig$in,
        canvas = _props4.viewer.canvas;


    if (!canvas) return;

    var controlledFilters = ['brightness', 'contrast', 'saturate', 'grayscale', 'invert'];

    var currentFilters = canvas.style.filter.split(' ');
    var newFilters = currentFilters.filter(function (filter) {
      return !controlledFilters.some(function (type) {
        return filter.includes(type);
      });
    });
    newFilters.push('brightness(' + brightness + '%)');
    newFilters.push('contrast(' + contrast + '%)');
    newFilters.push('saturate(' + saturate + '%)');
    newFilters.push('grayscale(' + grayscale + '%)');
    newFilters.push('invert(' + invert + '%)');
    canvas.style.filter = newFilters.join(' ');
  };

  MiradorImageTools.prototype.toggleState = function toggleState() {
    var _props5 = this.props,
        open = _props5.open,
        updateWindow = _props5.updateWindow,
        windowId = _props5.windowId;


    updateWindow(windowId, { imageToolsOpen: !open });
  };

  MiradorImageTools.prototype.toggleRotate = function toggleRotate(value) {
    var _props6 = this.props,
        updateViewport = _props6.updateViewport,
        _props6$viewConfig = _props6.viewConfig,
        _props6$viewConfig$fl = _props6$viewConfig.flip,
        flip = _props6$viewConfig$fl === undefined ? false : _props6$viewConfig$fl,
        _props6$viewConfig$ro = _props6$viewConfig.rotation,
        rotation = _props6$viewConfig$ro === undefined ? 0 : _props6$viewConfig$ro,
        windowId = _props6.windowId;


    var offset = flip ? -1 * value : value;

    updateViewport(windowId, { rotation: (rotation + offset) % 360 });
  };

  MiradorImageTools.prototype.toggleFlip = function toggleFlip() {
    var _props7 = this.props,
        updateViewport = _props7.updateViewport,
        _props7$viewConfig$fl = _props7.viewConfig.flip,
        flip = _props7$viewConfig$fl === undefined ? false : _props7$viewConfig$fl,
        windowId = _props7.windowId;


    updateViewport(windowId, { flip: !flip });
  };

  MiradorImageTools.prototype.render = function render() {
    var _this2 = this;

    var _props8 = this.props,
        classes = _props8.classes,
        containerId = _props8.containerId,
        enabled = _props8.enabled,
        open = _props8.open,
        viewer = _props8.viewer,
        windowId = _props8.windowId,
        width = _props8.width,
        palette = _props8.theme.palette,
        _props8$viewConfig = _props8.viewConfig,
        _props8$viewConfig$fl = _props8$viewConfig.flip,
        flip = _props8$viewConfig$fl === undefined ? false : _props8$viewConfig$fl,
        _props8$viewConfig$br = _props8$viewConfig.brightness,
        brightness = _props8$viewConfig$br === undefined ? 100 : _props8$viewConfig$br,
        _props8$viewConfig$co = _props8$viewConfig.contrast,
        contrast = _props8$viewConfig$co === undefined ? 100 : _props8$viewConfig$co,
        _props8$viewConfig$sa = _props8$viewConfig.saturate,
        saturate = _props8$viewConfig$sa === undefined ? 100 : _props8$viewConfig$sa,
        _props8$viewConfig$gr = _props8$viewConfig.grayscale,
        grayscale = _props8$viewConfig$gr === undefined ? 0 : _props8$viewConfig$gr,
        _props8$viewConfig$in = _props8$viewConfig.invert,
        invert = _props8$viewConfig$in === undefined ? 0 : _props8$viewConfig$in,
        t = _props8.t;


    if (!viewer || !enabled) return null;

    var backgroundColor = palette.shades.main;
    var foregroundColor = palette.getContrastText(backgroundColor);
    var isSmallDisplay = ['xs', 'sm'].indexOf(width) >= 0;

    /** Button for toggling the menu */
    var toggleButton = React.createElement(
      'div',
      { className: isSmallDisplay && open ? classes.borderContainer : '' },
      React.createElement(
        MiradorMenuButton,
        {
          'aria-expanded': open,
          'aria-haspopup': true,
          'aria-label': t('collapse', { context: open ? 'open' : 'close' }),
          containerId: containerId,
          onClick: this.toggleState
        },
        open ? React.createElement(CloseSharpIcon, null) : React.createElement(TuneSharpIcon, null)
      )
    );
    return React.createElement(
      'div',
      { className: 'MuiPaper-elevation4 ' + classes.root },
      isSmallDisplay && toggleButton,
      open && React.createElement(
        React.Fragment,
        null,
        React.createElement(
          'div',
          { className: classes.borderContainer },
          React.createElement(ImageRotation, {
            containerId: containerId,
            label: t('rotateRight'),
            onClick: function onClick() {
              return _this2.toggleRotate(90);
            },
            variant: 'right'
          }),
          React.createElement(ImageRotation, {
            containerId: containerId,
            label: t('rotateLeft'),
            onClick: function onClick() {
              return _this2.toggleRotate(-90);
            },
            variant: 'left'
          }),
          React.createElement(ImageFlip, {
            label: t('flip'),
            onClick: this.toggleFlip,
            flipped: flip,
            containerId: containerId
          })
        ),
        React.createElement(
          'div',
          { className: classes.borderContainer },
          React.createElement(
            ImageTool,
            {
              type: 'brightness',
              label: t('brightness'),
              max: 200,
              windowId: windowId,
              value: brightness,
              foregroundColor: foregroundColor,
              containerId: containerId,
              onChange: this.handleChange('brightness')
            },
            React.createElement(BrightnessIcon, null)
          ),
          React.createElement(
            ImageTool,
            {
              type: 'contrast',
              label: t('contrast'),
              max: 200,
              windowId: windowId,
              value: contrast,
              foregroundColor: foregroundColor,
              containerId: containerId,
              onChange: this.handleChange('contrast')
            },
            React.createElement(ContrastIcon, { style: { transform: 'rotate(180deg)' } })
          ),
          React.createElement(
            ImageTool,
            {
              type: 'saturate',
              label: t('saturation'),
              max: 200,
              windowId: windowId,
              value: saturate,
              foregroundColor: foregroundColor,
              containerId: containerId,
              onChange: this.handleChange('saturate')
            },
            React.createElement(GradientIcon, null)
          ),
          React.createElement(
            ImageTool,
            {
              type: 'grayscale',
              variant: 'toggle',
              label: t('greyscale'),
              windowId: windowId,
              value: grayscale,
              backgroundColor: backgroundColor,
              foregroundColor: foregroundColor,
              containerId: containerId,
              onChange: this.handleChange('grayscale')
            },
            React.createElement(TonalityIcon, null)
          ),
          React.createElement(
            ImageTool,
            {
              type: 'invert',
              variant: 'toggle',
              label: t('invert'),
              windowId: windowId,
              value: invert,
              foregroundColor: foregroundColor,
              containerId: containerId,
              onChange: this.handleChange('invert')
            },
            React.createElement(InvertColorsIcon, null)
          )
        ),
        React.createElement(
          'div',
          { className: isSmallDisplay ? '' : classes.borderContainer },
          React.createElement(
            MiradorMenuButton,
            {
              'aria-label': t('revert'),
              containerId: containerId,
              onClick: this.handleReset
            },
            React.createElement(ReplaySharpIcon, null)
          )
        )
      ),
      !isSmallDisplay && toggleButton
    );
  };

  return MiradorImageTools;
}(Component);

MiradorImageTools.propTypes = process.env.NODE_ENV !== "production" ? {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  containerId: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  open: PropTypes.bool,
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateViewport: PropTypes.func.isRequired,
  updateWindow: PropTypes.func.isRequired,
  viewer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  viewConfig: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  windowId: PropTypes.string.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired
} : {};

MiradorImageTools.defaultProps = {
  enabled: true,
  open: true,
  viewer: undefined,
  viewConfig: {}
};

// Export without wrapping HOC for testing.
export var TestableImageTools = MiradorImageTools;

export default compose(withStyles(styles), withWidth())(MiradorImageTools);