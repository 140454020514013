{
  "translation": {
    "aboutThisItem": "有關此物件",
    "addedFromUrl": "(從URL添加)",
    "addManifestUrl": "來源",
    "addManifestUrlHelp": "IIIF資源的URL",
    "addResource": "添加資源",
    "annotationCanvasLabel_1/1": "物件: [{{label}}]",
    "annotationCanvasLabel_1/2": "左方: [{{label}}]",
    "annotationCanvasLabel_2/2": "右方: [{{label}}]",
    "annotations": "注釋",
    "attribution": "著作權",
    "attributionTitle": "著作權",
    "book": "書籍",
    "bottom": "下方",
    "cancel": "取消",
    "canvasIndex": "索引",
    "changeTheme": "變更佈景主題",
    "closeAddResourceForm": "關閉表格",
    "closeAddResourceMenu": "關閉資源列表",
    "closeCompanionWindow": "關閉附屬視窗",
    "closeWindow": "關閉視窗",
    "collapseSection": "關閉{{section}}分頁",
    "collapseSidePanel": "關閉邊欄",
    "itemList": "標題列表",
    "copy": "複製",
    "currentItem": "目前物件",
    "currentItem_1/1": "目前物件",
    "currentItem_1/2": "左方",
    "currentItem_2/2": "右方",
    "dark": "黑色主題",
    "dismiss": "關閉信息",
    "highlightAllAnnotations": "顯示所有注釋",
    "downloadExport": "滙出桌面排版",
    "downloadExportWorkspace": "滙出桌面排版",
    "elastic": "彈性",
    "elasticDescription": "在桌面上自由擺放視窗",
    "emptyResourceList": "資源列表沒有物件",
    "errorDialogConfirm": "確定",
    "errorDialogTitle": "發生錯誤",
    "exitFullScreen": "退出全螢幕",
    "expandSection": "開啟{{section}}分頁",
    "expandSidePanel": "開啟邊欄",
    "fetchManifest": "添加",
    "fullScreen": "全螢幕",
    "gallery": "矩列",
    "hideZoomControls": "隱藏縮放選項",
    "iiif_homepage": "有關此資源",
    "iiif_manifest": "IIIF",
    "iiif_renderings": "其他格式",
    "iiif_seeAlso": "另見",
    "import" : "滙入",
    "importWorkspace": "滙入桌面排版",
    "importWorkspaceHint": "在此貼上Mirador 3排版設定碼",
    "item": "物件: {{label}}",
    "language": "語言",
    "light": "白色主題",
    "links": "連結",
    "listAllOpenWindows": "切換至視窗",
    "login": "登入",
    "manifestError": "無法增添資源:",
    "maximizeWindow": "視窗最大化",
    "minimizeWindow": "視窗最小化",
    "mirador": "Mirador",
    "miradorResources": "Mirador資源",
    "miradorViewer": "Mirador閱覽器",
    "mosaic": "馬賽克",
    "mosaicDescription": "在桌面上以格狀方式排列視窗",
    "moveCompanionWindowToBottom": "移至下方",
    "moveCompanionWindowToRight": "移至右方",
    "nextCanvas": "下一頁",
    "numItems": "{{number}} 項物件",
    "off": "關閉",
    "openCompanionWindow_annotations": "注釋",
    "openCompanionWindow_attribution": "著作權",
    "openCompanionWindow_canvas": "目錄",
    "openCompanionWindow_info": "資訊",
    "openInCompanionWindow": "移至新附屬視窗",
    "openWindows": "現有視窗",
    "pagination": "{{current}} / {{total}}",
    "position": "位置",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "上一頁",
    "related": "相關資訊",
    "resource": "資源",
    "right": "右方",
    "rights": "版權",
    "selectWorkspaceMenu": "選擇桌面排版方式",
    "showingNumAnnotations": "顯示 {{number}} 項注釋",
    "showZoomControls": "顯示縮放選項",
    "sidebarPanelsNavigation": "切換邊欄",
    "single": "單項",
    "startHere": "按此開始",
    "theme": "佈景主題",
    "thumbnailList": "縮圖列表",
    "thumbnailNavigation": "縮圖",
    "thumbnails": "顯示縮圖",
    "toggleWindowSideBar": "切換邊欄開關",
    "tryAgain": "請重試",
    "untitled": "[無標題]",
    "view": "物件排列方式",
    "welcome": "歡迎使用Mirador",
    "window": "視窗: {{label}}",
    "windowMenu": "視窗選項",
    "windowNavigation": "切換視窗",
    "windowPluginButtons": "插件",
    "workspace": "桌面",
    "workspaceFullScreen": "全螢幕",
    "workspaceMenu": "桌面設定",
    "workspaceOptions": "桌面選項",
    "workspaceSelectionTitle": "選擇桌面排版方式",
    "zoomIn": "放大",
    "zoomOut": "放小",
    "zoomReset": "重設縮放"
  }
}
