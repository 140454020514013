{
  "translation": {
    "aboutMirador": "A propos du projet Mirador",
    "aboutThisItem": "Informations",
    "addedFromUrl": "(Ajouté depuis une URL)",
    "addManifestUrl": "Emplacement de la ressource",
    "addManifestUrlHelp": "Coller ici l'URL de la ressource IIIF",
    "addResource": "Ajouter une ressource",
    "annotationCanvasLabel_1/1": "Item : [{{label}}]",
    "annotationCanvasLabel_1/2": "Gauche : [{{label}}]",
    "annotationCanvasLabel_2/2": "Droite : [{{label}}]",
    "annotations": "Annotations",
    "attribution": "Attribution",
    "attributionTitle": "Droits",
    "authenticationFailed": "L'authentification a échoué.",
    "authenticationRequired": "Authentification requise pour un accès complet",
    "backToResults": "Retour aux résultats",
    "book": "Livre",
    "bottom": "Bas",
    "cancel": "Annuler",
    "canvasIndex": "Index",
    "changeTheme": "Changer le thème",
    "clearSearch": "effacer",
    "closeAddResourceForm": "Fermer le panneau",
    "closeAddResourceMenu": "Fermer la liste des ressources",
    "closeCompanionWindow": "Fermer le panneau",
    "closeWindow": "Fermer cette fenêtre",
    "collapseSection": "Replier la section \"{{section}}\"",
    "collapseSidePanel": "Replier le panneau",
    "itemList": "Liste compacte",
    "continue": "Continuer",
    "copy": "Copier",
    "currentItem": "Image courante",
    "currentItem_1/1": "Image courante",
    "currentItem_1/2": "Gauche",
    "currentItem_2/2": "Droite",
    "dark": "Thème sombre",
    "digitizedView": "Vue de l'objet numérique",
    "dismiss": "Rejeter",
    "highlightAllAnnotations": "Afficher tout",
    "displayNoAnnotations": "Masquer tout",
    "downloadExport": "Exporter l'espace de travail",
    "downloadExportWorkspace": "Exporter l'espace de travail",
    "elastic": "Elastique",
    "elasticDescription": "Déplacer et redimensionner librement les fenêtres dans un espace de travail non limité par l'écran. Les fenêtres peuvent être superposées.",
    "emptyResourceList": "Votre liste de ressource est vide",
    "error": "Erreur",
    "errorDialogConfirm": "OK",
    "errorDialogTitle": "Une erreur est survenue",
    "exitFullScreen": "Quitter le plein écran",
    "expandSection": "Déplier la section \"{{section}}\"",
    "expandSidePanel": "Déplier le panneau",
    "exportCopied": "La configuration de l'espace de travail a été copiée dans votre presse-papier",
    "fetchManifest": "Ajouter",
    "fullScreen": "Plein écran",
    "gallery": "Galerie",
    "hideZoomControls": "Désactiver les commandes de zoom",
    "iiif_homepage": "A propos de cette ressource",
    "iiif_manifest": "Manifeste IIIF",
    "iiif_renderings": "Formats alternatifs",
    "iiif_seeAlso": "Voir aussi",
    "import" : "Importer",
    "importWorkspace": "Importer un espace de travail",
    "importWorkspaceHint": "Insérer une configuration Mirador 3 à importer",
    "item": "Item : {{label}}",
    "jsError": "Détails techniques",
    "jsStack": "{{ stack }}",
    "language": "Langue",
    "layer_hide": "Masquer le calque",
    "layer_move": "Déplacer le calque",
    "layer_opacity": "Opacité du calque",
    "layer_show": "Afficher le calque",
    "layer_moveToTop": "Déplacer le calque en haut",
    "layers": "Calques",
    "light": "Thème clair",
    "links": "Liens",
    "listAllOpenWindows": "Lister les fenêtres",
    "login": "Se connecter",
    "logout": "Se déconnecter",
    "manifestError": "Cette ressource ne peut pas être ajoutée :",
    "maximizeWindow": "Agrandir cette fenêtre",
    "minimizeWindow": "Réduire cette fenêtre",
    "mirador": "Mirador",
    "miradorResources": "Ressources Mirador",
    "miradorViewer": "Visualiseur Mirador",
    "more": "plus...",
    "moreResults": "Plus de résultats",
    "mosaic": "Mosaïque",
    "mosaicDescription": "Déplacer et redimensionner les fenêtres les unes par rapport aux autres dans l'espace disponible sur l'écran",
    "moveCompanionWindowToBottom": "Déplacer en bas",
    "moveCompanionWindowToRight": "Déplacer à droite",
    "nextCanvas": "Suivant",
    "noItemSelected": "Aucun élément sélectionné",
    "numItems": "{{number}} image",
    "numItems_plural": "{{number}} images",
    "off": "aucun",
    "openCompanionWindow_annotations": "Annotations",
    "openCompanionWindow_attribution": "Droits",
    "openCompanionWindow_canvas": "Index",
    "openCompanionWindow_info": "Informations",
    "openCompanionWindow_layers": "Calques",
    "openCompanionWindow_search": "Rechercher",
    "openInCompanionWindow": "Ouvrir dans un panneau séparé",
    "openWindows": "Fenêtres ouvertes",
    "pagination": "{{current}} sur {{total}}",
    "position": "Position",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "Précédent",
    "related": "En relation",
    "resource": "Ressource",
    "retry": "Réessayer",
    "right": "Droite",
    "rights": "Licence",
    "scroll": "Défilement horizontal",
    "searchInputLabel": "Chercher un mot",
    "searchNextResult": "Résultat suivant",
    "searchNoResults": "Aucun résultat trouvé",
    "searchPreviousResult": "Résultat précédent",
    "searchSubmitAria": "Lancer la recherche",
    "searchTitle": "Rechercher",
    "selectWorkspaceMenu": "Changer de type d'espace de travail",
    "showingNumAnnotations": "{{number}} annotation affichée",
    "showingNumAnnotations_plural": "{{number}} annotations affichées",
    "showCollection": "Voir la collection",
    "showZoomControls": "Activer les commandes de zoom",
    "sidebarPanelsNavigation": "Navigation dans les panneaux latéraux",
    "single": "Simple",
    "startHere": "Commencer ici",
    "suggestSearch": "Rechercher \"{{ query }}\" dans ce document",
    "tableOfContentsList": "Sommaire",
    "theme": "Thème",
    "thumbnailList": "Liste des vignettes",
    "thumbnailNavigation": "Vignettes",
    "thumbnails": "Afficher les vignettes",
    "toggleWindowSideBar": "Afficher le menu latéral",
    "totalCollections": "{{count}} collection",
    "totalCollections_plural": "{{count}} collections",
    "totalManifests": "{{count}} manifeste",
    "totalManifests_plural": "{{count}} manifestes",
    "tryAgain": "Essayer à nouveau",
    "untitled": "[Sans titre]",
    "view": "Voir les images en mode",
    "welcome": "Bienvenue dans Mirador",
    "window": "Fenêtre : {{label}}",
    "windowMenu": "Options de fenêtre",
    "windowNavigation": "Navigation dans les fenêtres",
    "windowPluginButtons": "Options",
    "windowPluginMenu": "Autres options et outils",
    "workspace": "Espace de travail",
    "workspaceNavigation": "Menu de l'espace de travail",
    "workspaceFullScreen": "Plein écran",
    "workspaceMenu": "Réglages de l'espace de travail",
    "workspaceOptions": "Options de l'espace de travail",
    "workspaceSelectionTitle": "Sélectionner un type d'espace de travail",
    "zoomIn": "Zoom avant",
    "zoomOut": "Zoom arrière",
    "zoomReset": "Réinitialiser le zoom"
  }
}
