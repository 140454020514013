{
  "translation": {
    "aboutMirador": "Về dự án Mirador",
    "aboutThisItem": "Về khoản mục này",
    "addedFromUrl": "(Được thêm từ URL)",
    "addManifestUrl": "Vị trí tài nguyên",
    "addManifestUrlHelp": "URL của tài nguyên IIIF",
    "addResource": "Bổ sung tài nguyên",
    "annotationCanvasLabel_1/1": "Khoản mục: [{{label}}]",
    "annotationCanvasLabel_1/2": "Trái: [{{label}}]",
    "annotationCanvasLabel_2/2": "Phải: [{{label}}]",
    "annotations": "Chú giải",
    "attribution": "Quyền hạn",
    "attributionTitle": "Quyền",
    "authenticationFailed": "Xác thực thất bại.",
    "authenticationRequired": "Xác thực được yêu cầu cho truy nhập đầy đủ",
    "backToResults": "Trở lại kết quả",
    "book": "Sách",
    "bottom": "Đáy",
    "cancel": "Huỷ bỏ",
    "canvasIndex": "Chỉ mục",
    "changeTheme": "Đổi chủ đề",
    "clearSearch": "xoá",
    "close": "Đóng",
    "closeAddResourceForm": "Đóng mẫu",
    "closeAddResourceMenu": "Đóng danh sách tài nguyên",
    "closeCompanionWindow": "Đóng panel",
    "closeWindow": "Đóng cửa sổ",
    "collapseSection": "Co sập {{section}} mục",
    "collapseSidePanel": "Co sập thanh bên",
    "collection": "Tuyển tập",
    "itemList": "Danh sách khoản mục",
    "continue": "Tiếp tục",
    "copy": "Sao",
    "currentItem": "Khoản mục hiện thời",
    "currentItem_1/1": "Khoản mục hiện thời",
    "currentItem_1/2": "Trái",
    "currentItem_2/2": "Phải",
    "dark": "Chủ đề tối",
    "digitizedView": "Cái nhìn số hoá",
    "dismiss": "Bác bỏ",
    "highlightAllAnnotations": "Làm nổi bật tất",
    "displayNoAnnotations": "Không làm nổi bật",
    "downloadExport": "Vùng xuất khẩu",
    "downloadExportWorkspace": "Vùng xuất khẩu",
    "elastic": "Co giãn",
    "elasticDescription": "Di chuyển và định cỡ cửa sổ tự do trong vùng vô giới hạn. Cửa sổ có thể chèn lấp.",
    "emptyResourceList": "Danh sách tài nguyên của bạn là trống",
    "error": "Lỗi",
    "errorDialogConfirm": "OK",
    "errorDialogTitle": "Lỗi đã xuất hiện",
    "exitFullScreen": "Ra khỏi toàn màn hình",
    "expandSection": "Mở rộng {{section}} mục",
    "expandSidePanel": "Mở rộng thanh bên",
    "exportCopied": "Cấu hình vùng làm việc được sao vào bảng đệm của bạn",
    "fetchManifest": "Thêm",
    "fullScreen": "Toàn màn hình",
    "gallery": "Phòng tranh",
    "hideZoomControls": "Ẩn điều khiển thu phóng",
    "iiif_homepage": "Về tài nguyên này",
    "iiif_manifest": "Bản kê IIIF",
    "iiif_renderings": "Dạng thức luân phiên",
    "iiif_seeAlso": "Cũng xem",
    "import" : "Nhập khẩu",
    "importWorkspace": "Vùng nhập khẩu",
    "importWorkspaceHint": "Dán cấu hình Mirador 3 để được nhập khẩu",
    "item": "Khoản mục: {{label}}",
    "jsError": "Chi tiết kĩ thuật",
    "jsStack": "{{ stack }}",
    "language": "Ngôn ngữ",
    "layer_hide": "Giấu tầng",
    "layer_move": "Chuyển tầng",
    "layer_opacity": "Làm mờ tầng",
    "layer_show": "Hiện tầng",
    "layer_moveToTop": "Chuyển tầng lên đỉnh",
    "layers": "Tầng",
    "light": "Chủ đề sáng",
    "links": "Móc nối",
    "listAllOpenWindows": "Nhảy tới cửa sổ",
    "login": "Đăng nhập",
    "logout": "Đăng xuất",
    "manifestError": "Tài nguyên không thể được bổ sung:",
    "maximizeWindow": "Cực đại cửa sổ",
    "minimizeWindow": "Cực tiểu cửa sổ",
    "mirador": "Mirador",
    "miradorResources": "Tài nguyên Mirador",
    "miradorViewer": "Bộ xem Mirador",
    "more": "thêm...",
    "moreResults": "Thêm kết quả",
    "mosaic": "Mosaic",
    "mosaicDescription": "Di chuyển và định cỡ cửa sổ trong quan hệ lẫn nhau, bên trong khung thấy được.",
    "moveCompanionWindowToBottom": "Chuyển tới đáy",
    "moveCompanionWindowToRight": "Chuyển sang phải",
    "nextCanvas": "Khoản mục tiếp",
    "noItemSelected": "Không khoản mục nào được chọn",
    "numItems": "{{number}} khoản mục",
    "off": "Off",
    "openCompanionWindow_annotations": "Chú giải",
    "openCompanionWindow_attribution": "Quyền",
    "openCompanionWindow_canvas": "Chỉ mục",
    "openCompanionWindow_info": "Thông tin",
    "openCompanionWindow_layers": "Tầng",
    "openCompanionWindow_search": "Tìm",
    "openInCompanionWindow": "Mở trong ngăn tách rời",
    "openWindows": "Cửa sổ mở hiện thời",
    "pagination": "{{current}} trong {{total}}",
    "position": "Vị trí",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "Khoản mục trước",
    "related": "Có liên quan",
    "resource": "Tài nguyên",
    "retry": "Thử lại",
    "right": "Quyền",
    "rights": "Cấp phép",
    "scroll": "Cuộn",
    "searchInputLabel": "Từ tìm kiếm",
    "searchNextResult": "Kết quả tiếp",
    "searchNoResults": "Không tìm được kết quả nào",
    "searchPreviousResult": "Kết quả trước",
    "searchSubmitAria": "Đệ trình việc tìm",
    "searchTitle": "Tìm",
    "selectWorkspaceMenu": "Chọn kiểu vùng làm việc",
    "showingNumAnnotations": "Hiện {{number}} chú giải",
    "showCollection": "Hiện bộ sưu tập",
    "showZoomControls": "Hiện kiểm soát thu phóng",
    "sidebarPanelsNavigation": "Dẫn lái ngăn thanh bên",
    "single": "Chỉ một",
    "startHere": "Bắt đầu ở đây",
    "suggestSearch": "Tìm tài liệu này cho \"{{ query }}\"",
    "tableOfContentsList": "Mục lục",
    "theme": "Chủ đề",
    "thumbnailList": "Danh sách ảnh thu nhỏ",
    "thumbnailNavigation": "Ảnh thu nhỏ",
    "thumbnails": "Ảnh thu nhỏ",
    "toggleWindowSideBar": "chốt thanh bên",
    "totalCollections": "{{count}} bộ sưu tập",
    "totalManifests": "{{count}} bản kê",
    "tryAgain": "Thử lại",
    "untitled": "[Untitled]",
    "view": "Xem",
    "welcome": "Chào mừng bạn tới Mirador",
    "window": "Cửa sổ: {{label}}",
    "windowMenu": "Xem cửa sổ & hiển thị ảnh thu nhỏ",
    "windowNavigation": "Dẫn lái cửa sổ",
    "windowPluginButtons": "Tuỳ chọn",
    "windowPluginMenu": "Tuỳ chọn cửa sổ",
    "workspace": "Vùng làm việc",
    "workspaceNavigation": "Dẫn lái vùng làm việc",
    "workspaceFullScreen": "Toàn màn hình",
    "workspaceMenu": "Thiết đặt vùng làm việc",
    "workspaceOptions": "Tuỳ chọn vùng làm việc",
    "workspaceSelectionTitle": "Chọn kiểu vùng làm việc",
    "zoomIn": "Thu nhỏ",
    "zoomOut": "Phóng to",
    "zoomReset": "Đặt lại thu phóng"
  }
}