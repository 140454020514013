{
  "translation": {
    "aboutMirador": "Om Projekt Mirador",
    "aboutThisItem": "Om det här objektet",
    "addedFromUrl": "(Tillagd från URL)",
    "addManifestUrl": "Webbadress till samling eller manifest",
    "addManifestUrlHelp": "URL till en IIIF-resurs",
    "addResource": "Lägg till resurs",
    "annotationCanvasLabel_1/1": "Objekt: [{{label}}]",
    "annotationCanvasLabel_1/2": "Vänster: [{{label}}]",
    "annotationCanvasLabel_2/2": "Höger: [{{label}}]",
    "annotations": "Noteringar",
    "attribution": "Tillskrivning",
    "attributionTitle": "Rättigheter",
    "authenticationFailed": "Autentisering misslyckades.",
    "authenticationRequired": "Autentisering krävs för full åtkomst",
    "backToResults": "Tillbaka till resultat",
    "book": "Bok",
    "bottom": "Nederkant",
    "cancel": "Avbryt",
    "canvasIndex": "Index",
    "changeTheme": "Ändra tema",
    "clearSearch": "Ta bort sökning",
    "close": "Stäng",
    "closeAddResourceForm": "Stäng formulär",
    "closeAddResourceMenu": "Stäng resurslista",
    "closeCompanionWindow": "Stäng panel",
    "closeWindow": "Stäng fönster",
    "collapseSection": "Stäng sektionen \"{{section}}\"",
    "collapseSidePanel": "Stäng sidofält",
    "collection": "Samling",
    "itemList": "Objektlista",
    "continue": "Fortsätt",
    "copy": "Kopiera",
    "currentItem": "Aktuellt objekt",
    "currentItem_1/1": "Aktuellt objekt",
    "currentItem_1/2": "Vänster",
    "currentItem_2/2": "Höger",
    "dark": "Mörkt tema",
    "digitizedView": "Digitaliserad vy",
    "dismiss": "Stäng",
    "highlightAllAnnotations": "Markera alla",
    "displayNoAnnotations": "Avmarkera alla",
    "downloadExport": "Exportera arbetsyta",
    "downloadExportWorkspace": "Exportera arbetsyta",
    "elastic": "Elastisk",
    "elasticDescription": "Flytta och ändra storlek på fönster fritt i en obegränsad arbetsyta. Fönster kan överlappa.",
    "emptyResourceList": "Din resurslista är tom",
    "error": "Error",
    "errorDialogConfirm": "OK",
    "errorDialogTitle": "Ett problem uppstod",
    "exitFullScreen": "Lämna helskärmsläge",
    "expandSection": "Expandera sektionen \"{{section}}\"",
    "expandSidePanel": "Expandera sidofält",
    "exportCopied": "Konfiguration av arbetsytan har kopierats till dina urklipp",
    "fetchManifest": "Lägg till",
    "fullScreen": "Helskärmsläge",
    "gallery": "Galleri",
    "hideZoomControls": "Dölj zoomkontroller",
    "iiif_homepage": "Om den här resursen",
    "iiif_manifest": "IIIF manifest",
    "iiif_renderings": "Alternativa format",
    "iiif_seeAlso": "Se även",
    "import" : "Importera",
    "importWorkspace": "Importera arbetsyta",
    "importWorkspaceHint": "Klistra in en Mirador 3 konfiguration att importera",
    "item": "Objekt: {{label}}",
    "jsError": "Tekniska detaljer",
    "jsStack": "{{ stack }}",
    "language": "Språk",
    "layer_hide": "Dölj lager",
    "layer_move": "Flytta lager",
    "layer_opacity": "Lageropacitet",
    "layer_show": "Visa lager",
    "layer_moveToTop": "Flytta lager till toppen",
    "layers": "Lager",
    "light": "Ljust tema",
    "links": "Länkar",
    "listAllOpenWindows": "Gå till fönster",
    "login": "Logga in",
    "logout": "Logga ut",
    "manifestError": "Resursen kan inte läggas till:",
    "maximizeWindow": "Maximera fönster",
    "minimizeWindow": "Minimera fönster",
    "mirador": "Mirador",
    "miradorResources": "Miradorresurser",
    "miradorViewer": "Mirador bildvisare",
    "more": "mer...",
    "moreResults": "Fler resultat",
    "mosaic": "Mosaik",
    "mosaicDescription": "Flytta och ändra storlek på fönster i relation till varandra, innanför den synliga ramen.",
    "moveCompanionWindowToBottom": "Flytta till botten",
    "moveCompanionWindowToRight": "Flytta till höger",
    "nextCanvas": "Nästa objekt",
    "noItemSelected": "Inga valda objekt",
    "numItems": "{{number}} objekt",
    "off": "Av",
    "openCompanionWindow_annotations": "Noteringar",
    "openCompanionWindow_attribution": "Rättigheter",
    "openCompanionWindow_canvas": "Index",
    "openCompanionWindow_info": "Information",
    "openCompanionWindow_layers": "Lager",
    "openCompanionWindow_search": "Sök",
    "openInCompanionWindow": "Öppna i separat panel",
    "openWindows": "Öppna fönster",
    "pagination": "{{current}} av {{total}}",
    "position": "Position",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "Föregående objekt",
    "related": "Relaterat",
    "resource": "Resurs",
    "retry": "Försök igen",
    "right": "Till höger",
    "rights": "Licens",
    "scroll": "Scrolla",
    "searchInputLabel": "Sökord",
    "searchNextResult": "Nästa resultat",
    "searchNoResults": "Inga resultat hittades",
    "searchPreviousResult": "Föregående resultat",
    "searchResultsRemaining": "{{numLeft}} kvar",
    "searchSubmitAria": "Sök",
    "searchTitle": "Sök",
    "selectWorkspaceMenu": "Välj typ av arbetsyta",
    "showingNumAnnotations": "Visar {{number}} noteringar",
    "showCollection": "Visa samling",
    "showZoomControls": "Visa zoomkontroller",
    "sidebarPanelsNavigation": "Sidofältspaneler navigering",
    "single": "En och en",
    "startHere": "Börja här",
    "suggestSearch": "Sök i detta dokument efter \"{{ query }}\"",
    "tableOfContentsList": "Innehållsförteckning",
    "theme": "Tema",
    "thumbnailList": "Miniatyrlista",
    "thumbnailNavigation": "Miniatyrer",
    "thumbnails": "Miniatyrer",
    "toggleWindowSideBar": "Visa/dölj sidofält",
    "totalCollections": "{{count}} samlingar",
    "totalManifests": "{{count}} manifest",
    "tryAgain": "Försök igen",
    "untitled": "[namnlös]",
    "view": "Vy",
    "welcome": "Välkommen till Mirador",
    "window": "Fönster: {{label}}",
    "windowMenu": "Fönstervyer & miniatyrdisplay",
    "windowNavigation": "Fönsternavigation",
    "windowPluginButtons": "Inställningar",
    "windowPluginMenu": "Fönsterinställningar",
    "workspace": "Arbetsyta",
    "workspaceNavigation": "Navigera i arbetsyta",
    "workspaceFullScreen": "Helskärmsläge",
    "workspaceMenu": "Inställningar för arbetsyta",
    "workspaceOptions": "Fler val för arbetsyta",
    "workspaceSelectionTitle": "Välj typ av arbetsyta",
    "zoomIn": "Zooma in",
    "zoomOut": "Zooma ut",
    "zoomReset": "Återställ zoom"
  }
}
