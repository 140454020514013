{
  "translation": {
    "aboutMirador": "Project Miradorについて",
    "aboutThisItem": "この資料について",
    "addedFromUrl": "(URLで追加)",
    "addManifestUrl": "資料のURL",
    "addManifestUrlHelp": "IIIF資料のURL",
    "addResource": "資料の追加",
    "annotationCanvasLabel_1/1": "アイテム: [{{label}}]",
    "annotationCanvasLabel_1/2": "左: [{{label}}]",
    "annotationCanvasLabel_2/2": "右: [{{label}}]",
    "annotations": "アノテーション",
    "attribution": "帰属",
    "attributionTitle": "権利",
    "authenticationFailed": "認証失敗",
    "authenticationRequired": "フルアクセスには認証が必要",
    "backToResults": "結果に戻る",
    "book": "見開き",
    "bottom": "下部",
    "cancel": "キャンセル",
    "canvasIndex": "インデックス",
    "changeTheme": "テーマの変更",
    "clearSearch": "クリア",
    "close": "閉じる",
    "closeAddResourceForm": "フォームを閉じる",
    "closeAddResourceMenu": "資料一覧を閉じる",
    "closeCompanionWindow": "パネルを閉じる",
    "closeWindow": "ウインドウを閉じる",
    "collapseSection": "{{section}} セクションを畳む",
    "collapseSidePanel": "サイドバーを畳む",
    "collection": "コレクション",
    "itemList": "アイテム一覧",
    "continue": "続ける",
    "copy": "コピー",
    "currentItem": "現在のアイテム",
    "currentItem_1/1": "現在のアイテム",
    "currentItem_1/2": "左",
    "currentItem_2/2": "右",
    "dark": "ダークなテーマ",
    "digitizedView": "デジタルビュー",
    "dismiss": "片付け",
    "highlightAllAnnotations": "すべてを表示",
    "displayNoAnnotations": "アノテーションを非表示",
    "downloadExport": "ワークスペースをエクスポート",
    "downloadExportWorkspace": "ワークスペースをエクスポート",
    "elastic": "伸縮",
    "elasticDescription": "自由なウインドウの伸縮",
    "emptyResourceList": "資料の一覧が空です",
    "error": "エラー",
    "errorDialogConfirm": "OK",
    "errorDialogTitle": "エラー発生",
    "exitFullScreen": "全画面を解除",
    "expandSection": "セクション {{section}}を拡大",
    "expandSidePanel": "サイドバーを拡大",
    "exportCopied": "ワークスペースの設定がクリップボードにコピーされました。",
    "fetchManifest": "追加",
    "fullScreen": "全画面",
    "gallery": "ギャラリー",
    "hideZoomControls": "zoom制御を隠す",
    "iiif_homepage": "IIIFホームページ",
    "iiif_manifest": "IIIF マニフェスト",
    "iiif_renderings": "別の形式",
    "iiif_seeAlso": "参照",
    "import": "取り込み",
    "importWorkspace": "ワークスペースの取り込み",
    "importWorkspaceHint": "Mirador3の設定を貼り付け",
    "item": "アイテム: {{label}}",
    "jsError": "技術的な詳細",
    "jsStack": "{{ stack }}",
    "language": "言語",
    "layer_hide": "レイヤーを隠す",
    "layer_move": "レイヤーを動かす",
    "layer_opacity": "レイヤーの透過度",
    "layer_show": "レイヤーを表示",
    "layer_moveToTop": "レイヤーをトップへ",
    "layers": "レイヤー",
    "light": "明るいテーマ",
    "links": "リンク",
    "listAllOpenWindows": "ウインドウにジャンプ",
    "login": "ログイン",
    "logout": "ログアウト",
    "manifestError": "資料追加に失敗:",
    "maximizeWindow": "ウインドウを最大化",
    "minimizeWindow": "ウインドウを最小化",
    "mirador": "Mirador",
    "miradorResources": "Mirador資料",
    "miradorViewer": "Miradorビューワ",
    "more": "さらに...",
    "moreResults": "さらに結果を",
    "mosaic": "モザイク",
    "mosaicDescription": "モザイク表示",
    "moveCompanionWindowToBottom": "下部に移動",
    "moveCompanionWindowToRight": "右に移動",
    "nextCanvas": "次のアイテム",
    "noItemSelected": "アイテムが未選択",
    "numItems": "{{number}} アイテム",
    "off": "オフ",
    "openCompanionWindow_annotations": "アノテーション",
    "openCompanionWindow_attribution": "権利",
    "openCompanionWindow_canvas": "インデックス",
    "openCompanionWindow_info": "情報",
    "openCompanionWindow_layers": "レイヤー",
    "openCompanionWindow_search": "検索",
    "openInCompanionWindow": "別のパネルで開く",
    "openWindows": "現在開いているウインドウ",
    "pagination": "{{current}} of {{total}}",
    "position": "位置",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "前のアイテム",
    "related": "関連",
    "resource": "資料",
    "retry": "リトライ",
    "right": "右側",
    "rights": "利用条件",
    "scroll": "スクロール",
    "searchInputLabel": "検索語",
    "searchNextResult": "次の結果",
    "searchNoResults": "ヒットせず",
    "searchPreviousResult": "前の結果",
    "searchSubmitAria": "検索",
    "searchTitle": "検索",
    "selectWorkspaceMenu": "ワークスペースタイプの選択",
    "showingNumAnnotations": "アノテーション {{number}} を表示",
    "showCollection": "コレクションを表示",
    "showZoomControls": "ズーム操作を表示",
    "sidebarPanelsNavigation": "サイドバーパネルの操作",
    "single": "単一",
    "startHere": "ここから始める",
    "suggestSearch": "この文書を \"{{ query }}\" で検索",
    "tableOfContentsList": "目次",
    "theme": "テーマ",
    "thumbnailList": "サムネイル一覧",
    "thumbnailNavigation": "サムネイル",
    "thumbnails": "サムネイル表示",
    "toggleWindowSideBar": "サイドバー切り替え",
    "totalCollections": "{{count}} コレクション",
    "totalManifests": "{{count}} マニフェスト",
    "tryAgain": "もう一度試す",
    "untitled": "[タイトル無し]",
    "view": "表示の仕方",
    "welcome": "Miradorにようこそ",
    "window": "{{label}} ウインドウ",
    "windowMenu": "ウインドウメニュー",
    "windowNavigation": "ウィンドウ操作",
    "windowPluginButtons": "オプション",
    "windowPluginMenu": "ウインドウオプション",
    "workspace": "ワークスペース",
    "workspaceNavigation": "ワークスペースナビ",
    "workspaceFullScreen": "全画面",
    "workspaceMenu": "ワークスペースの設定",
    "workspaceOptions": "ワークスペースのオプション",
    "workspaceSelectionTitle": "ワークスペースのタイプを選択",
    "zoomIn": "ズームイン",
    "zoomOut": "ズームアウト",
    "zoomReset": "ズームを戻す"
  }
}
