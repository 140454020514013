{
  "translation": {
    "aboutMirador": "Apie Project Mirador",
    "aboutThisItem": "Apie šį įrašą",
    "addedFromUrl": "(Pridėta naudojant URL)",
    "addManifestUrl": "Šaltinio adresas",
    "addManifestUrlHelp": "Nuoroda į IIIF šaltinį",
    "addResource": "Pridėti šaltinį",
    "annotationCanvasLabel_1/1": "Įrašas: [{{label}}]",
    "annotationCanvasLabel_1/2": "Kairė: [{{label}}]",
    "annotationCanvasLabel_2/2": "Dešinė: [{{label}}]",
    "annotations": "Anotacijos",
    "attribution": "Priskyrimas",
    "attributionTitle": "Teisės",
    "authenticationFailed": "Autentifikavimo klaida.",
    "authenticationRequired": "Pilnai prieigai būtina autentifikacija",
    "backToResults": "Grįžti į rezultatus",
    "book": "Knyga",
    "bottom": "Apačia",
    "cancel": "Atšaukti",
    "canvasIndex": "Indeksas",
    "changeTheme": "Keisti temą",
    "clearSearch": "valyti",
    "closeAddResourceForm": "Uždaryti paraišką",
    "closeAddResourceMenu": "Uždaryti šaltinių sąrašą",
    "closeCompanionWindow": "Uždaryti panelę",
    "closeWindow": "Uždaryti langą",
    "collapseSection": "Suskleisti \"{{section}}\" sekciją",
    "collapseSidePanel": "Suskleisti šoninę juostą",
    "itemList": "Įrašų sąrašas",
    "continue": "Tęsti",
    "copy": "Kopijuoti",
    "currentItem": "Dabartinis įrašas",
    "currentItem_1/1": "Dabartinis įrašas",
    "currentItem_1/2": "Kairė",
    "currentItem_2/2": "Dešinė",
    "dark": "Tamsi tema",
    "digitizedView": "Skaitmenintas vaizdas",
    "dismiss": "Atmesti",
    "highlightAllAnnotations": "Paryškinti viską",
    "displayNoAnnotations": "Slėpti viską",
    "downloadExport": "Išsaugoti darbalaukį",
    "downloadExportWorkspace": "Išsaugoti darbalaukį",
    "elastic": "Lankstus",
    "elasticDescription": "Langų dydis ir vieta gali būti laisvai valdomi. Langai gali persidengti.",
    "emptyResourceList": "Jūsų šaltinių sąrašas yra tuščias",
    "error": "Klaida",
    "errorDialogConfirm": "Gerai",
    "errorDialogTitle": "Įvyko klaida",
    "exitFullScreen": "Išjungti pilno ekrano režimą",
    "expandSection": "Išplėsti \"{{section}}\" sekciją",
    "expandSidePanel": "Išplėsti šoninę juostą",
    "exportCopied": "Darbalaukio nustatymai nukopijuoti",
    "fetchManifest": "Pridėti",
    "fullScreen": "Pilnas ekranas",
    "gallery": "Galerija",
    "hideZoomControls": "Slėpti priartinimo valdymą",
    "iiif_homepage": "Apie šį šaltinį",
    "iiif_manifest": "IIIF manifestas",
    "iiif_renderings": "Kiti formatai",
    "iiif_seeAlso": "Susiję",
    "import": "Įkelti",
    "importWorkspace": "Įkelti darbalaukį",
    "importWorkspaceHint": "Įterpkite Mirador 3 nustatymus įkėlimui",
    "item": "Įrašas: {{label}}",
    "jsError": "Techninė informacija",
    "jsStack": "{{ stack }}",
    "language": "Kalba",
    "layer_hide": "Slėpti sluoksnį",
    "layer_move": "Perkelti sluoksnį",
    "layer_opacity": "Sluoksnio nepermatomumas",
    "layer_show": "Rodyti sluoksnį",
    "layer_moveToTop": "Perkelti sluoksnį į viršų",
    "layers": "Sluoksniai",
    "light": "Šviesi tema",
    "links": "Nuorodos",
    "listAllOpenWindows": "Rodyti aktyvius langus",
    "login": "Prisijungti",
    "logout": "Atsijungti",
    "manifestError": "Šaltinis negali būti pridėtas:",
    "maximizeWindow": "Išdidinti langą",
    "minimizeWindow": "Sumažinti langą",
    "mirador": "Mirador",
    "miradorResources": "Mirador šaltiniai",
    "miradorViewer": "Mirador žiūryklė",
    "more": "daugiau...",
    "moreResults": "Daugiau rezultatų",
    "mosaic": "Mosaic",
    "mosaicDescription": "Langų dydžio ir pozicijos keitimai apribojami ekrano erdvėje.",
    "moveCompanionWindowToBottom": "Perkelti į apačią",
    "moveCompanionWindowToRight": "Perkelti į dešinę",
    "nextCanvas": "Kitas įrašas",
    "numItems": "{{number}} įrašas (-ai)",
    "off": "Išjungti",
    "openCompanionWindow_annotations": "Anotacijos",
    "openCompanionWindow_attribution": "Teisės",
    "openCompanionWindow_canvas": "Indeksas",
    "openCompanionWindow_info": "Informacija",
    "openCompanionWindow_layers": "Sluoksniai",
    "openCompanionWindow_search": "Paieška",
    "openInCompanionWindow": "Atidaryti kaip naują panelę",
    "openWindows": "Aktyvųs langai",
    "pagination": "{{current}} iš {{total}}",
    "position": "Pozicija",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "Buvęs įrašas",
    "related": "Susiję",
    "resource": "Šaltinis",
    "retry": "Pabandyti iš naujo",
    "right": "Dešinė",
    "rights": "Teisės",
    "scroll": "Paslinkti",
    "searchInputLabel": "paieškos terminas",
    "searchNextResult": "Kitas rezultatas",
    "searchNoResults": "Nėra rezultatų",
    "searchPreviousResult": "Buvęs rezultatas",
    "searchSubmitAria": "Pateikti paieškos užklausą",
    "searchTitle": "Ieškoti",
    "selectWorkspaceMenu": "Pasirinkti darbalaukio tipą",
    "showingNumAnnotations": "Rodoma {{number}} anotacija (-os)",
    "showCollection": "Rodyti kolekciją",
    "showZoomControls": "Rodyti priartinimo valdymą",
    "sidebarPanelsNavigation": "Šoninės juostos valdymas",
    "single": "Atskiras",
    "startHere": "Pradėkite čia",
    "suggestSearch": "Ieškoti \"{{ query }}\" visame documente",
    "tableOfContentsList": "Turinys",
    "theme": "Tema",
    "thumbnailList": "Miniatiūrų sąrašas",
    "thumbnailNavigation": "Miniatiūros",
    "thumbnails": "Miniatiūros",
    "toggleWindowSideBar": "Perjungti šoninę juostą",
    "totalCollections": "{{count}} kolekcija (-os)",
    "totalManifests": "{{count}} šaltinis (-iai)",
    "tryAgain": "Bandykite dar kartą",
    "untitled": "[Be pavadinimo]",
    "view": "Žiūrėti",
    "welcome": "Sveiki atvykę į Mirador",
    "window": "Langas: {{label}}",
    "windowMenu": "Langų & miniatiūrų atvaizdavimas",
    "windowNavigation": "Langų valdymas",
    "windowPluginButtons": "Pasirinkimai",
    "windowPluginMenu": "Langų pasirinkimai",
    "workspace": "Darbalaukis",
    "workspaceNavigation": "Darbalaukio valdymas",
    "workspaceFullScreen": "Pilnas ekranas",
    "workspaceMenu": "Darbalaukio nustatymai",
    "workspaceOptions": "Darbalaukio pasirinkimai",
    "workspaceSelectionTitle": "Pasirinkite darbalaukio tipą",
    "zoomIn": "Priartinti",
    "zoomOut": "Atitolinti",
    "zoomReset": "Atstatyti priartinimą"
  }
}
