{
  "translation": {
    "aboutMirador": "Informazioni su Mirador",
    "aboutThisItem": "Informazioni sull'oggetto",
    "addManifestUrl": "URL della risorsa",
    "addManifestUrlHelp": "L'URL di una risorsa IIIF",
    "addResource": "Aggiungi una risorsa",
    "addedFromUrl": "(Aggiunto dall'URL)",
    "annotationCanvasLabel_1/1": "Oggetto: [{{label}}]",
    "annotationCanvasLabel_1/2": "Sinistra: [{{label}}]",
    "annotationCanvasLabel_2/2": "Destra: [{{label}}]",
    "annotations": "Annotazioni",
    "attribution": "Attribuzione",
    "attributionTitle": "Diritti",
    "authenticationFailed": "Autenticazione fallita.",
    "authenticationRequired": "Autenticazione richiesta per l'accesso completo",
    "backToResults": "Torna ai risultati",
    "book": "Libro",
    "bottom": "Fondo",
    "cancel": "Cancella",
    "canvasIndex": "Indice",
    "changeTheme": "Cambia tema",
    "clearSearch": "pulisci",
    "close": "Chiudi",
    "closeAddResourceForm": "Chiudi il modulo",
    "closeAddResourceMenu": "Chiudi la lista di risorse",
    "closeCompanionWindow": "Chiudi il pannello",
    "closeWindow": "Chiudi finestra",
    "collapseSection": "Collassa la sezione \"{{section}}\"",
    "collapseSidePanel": "Collassa la barra laterale",
    "collection": "Collezione",
    "continue": "Continua",
    "copy": "Copia",
    "currentItem": "Oggetto corrente",
    "currentItem_1/1": "Oggetto corrente",
    "currentItem_1/2": "Sinistra",
    "currentItem_2/2": "Destra",
    "dark": "Tema scuro",
    "digitizedView": "Digitized view",
    "dismiss": "Dismiss",
    "displayNoAnnotations": "Nascondi evidenziazione",
    "downloadExport": "Esporta il workspace",
    "downloadExportWorkspace": "Esporta il workspace",
    "elastic": "Elastico",
    "elasticDescription": "Muovi e ridimensiona le finestre liberamente in un workspace illimitato. Le finestre possono sovrapporsi.",
    "emptyResourceList": "La tua lista di risorse è vuota.",
    "error": "Errore",
    "errorDialogConfirm": "OK",
    "errorDialogTitle": "Si è verificato un errore.",
    "exitFullScreen": "Esci da schermo intero",
    "expandSection": "Espandi la sezione \"{{section}}\"",
    "expandSidePanel": "Espandi la barra laterale",
    "exportCopied": "La configurazione del workspace è stata copiata nella tua clipboard",
    "fetchManifest": "Aggiungi",
    "fullScreen": "Schermo intero",
    "gallery": "Galleria",
    "hideZoomControls": "Nascondi i controlli di zoom",
    "highlightAllAnnotations": "Evidenzia tutto",
    "iiif_homepage": "Informazioni su questa risorsa",
    "iiif_manifest": "IIIF manifest",
    "iiif_renderings": "Formati alternativi",
    "iiif_seeAlso": "Vedi anche",
    "import": "Importa",
    "importWorkspace": "Importa workspace",
    "importWorkspaceHint": "Incolla una configurazione di Mirador 3 da importare",
    "item": "Oggetto: {{label}}",
    "itemList": "Lista compatta",
    "jsError": "Dettagli tecnici",
    "jsStack": "{{ stack }}",
    "language": "Lingua",
    "layer_hide": "Nascondi livello",
    "layer_move": "Sposta livello",
    "layer_moveToTop": "Sposta il livello in alto",
    "layer_opacity": "Opacità del livello",
    "layer_show": "Visualizza livello",
    "layers": "Livelli",
    "light": "Tema chiaro",
    "links": "Link",
    "listAllOpenWindows": "Vai alla finestra",
    "login": "Entra",
    "logout": "Esci",
    "manifestError": "La risorsa non può essere aggiunta:",
    "maximizeWindow": "Massimizza la finestra",
    "minimizeWindow": "Minimizza la finestra",
    "mirador": "Mirador",
    "miradorResources": "Risorse Mirador",
    "miradorViewer": "Visualizzatore Mirador",
    "more": "ancora...",
    "moreResults": "Più risultati",
    "mosaic": "Mosaico",
    "mosaicDescription": "Muovi e ridimensiona le finestre, tenendole all'interno dell'area visibile",
    "moveCompanionWindowToBottom": "Sposta in fondo",
    "moveCompanionWindowToRight": "Sposta a destra",
    "nextCanvas": "Prossimo oggetto",
    "noItemSelected": "Nessun oggetto selezionato",
    "numItems": "{{number}} oggetti",
    "numItems_plural": "{{number}} oggetti",
    "off": "Off",
    "openCompanionWindow_annotations": "Annotazioni",
    "openCompanionWindow_attribution": "Diritti",
    "openCompanionWindow_canvas": "Indice",
    "openCompanionWindow_info": "Informazioni",
    "openCompanionWindow_layers": "Livelli",
    "openCompanionWindow_search": "Cerca",
    "openInCompanionWindow": "Apri in un pannello separato",
    "openWindows": "Finestre aperte in questo momento",
    "pagination": "{{current}} di {{total}}",
    "position": "Posizione",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "Previous item",
    "related": "Contenuti collegati",
    "resource": "Risorsa",
    "retry": "Riprova",
    "right": "Right",
    "rights": "Licenza",
    "scroll": "Scorri",
    "searchInputLabel": "termini di ricerca",
    "searchNextResult": "Prossimo risultato",
    "searchNoResults": "Nessun risultato",
    "searchPreviousResult": "Risultato precedente",
    "searchResultsRemaining": "{{numLeft}} rimanenti",
    "searchSubmitAria": "Cerca",
    "searchTitle": "Cerca",
    "selectWorkspaceMenu": "Selezione il tipo di workspace",
    "showCollection": "Visualizza la collezione",
    "showZoomControls": "Mostra i controlli di zoom",
    "showingNumAnnotations": "Sto mostrando {{number}} annotazioni",
    "showingNumAnnotations_plural": "Visualizzando {{number}} annotazioni",
    "sidebarPanelsNavigation": "Navigazione dei pannelli della barra laterale",
    "single": "Singolo",
    "startHere": "Inizia qui",
    "suggestSearch": "Cerca in questo documento: \"{{ query }}\"",
    "tableOfContentsList": "Sommario",
    "theme": "Tema",
    "thumbnailList": "Lista thumbnail",
    "thumbnailNavigation": "Thumbnails",
    "thumbnails": "Thumbnails",
    "toggleWindowSideBar": "Apri/Chiudi la barra",
    "totalCollections": "{{count}} collezione",
    "totalCollections_plural": "{{count}} collezioni",
    "totalManifests": "{{count}} manifest",
    "totalManifests_plural": "{{count}} manifests",
    "tryAgain": "Riprova",
    "untitled": "[senza titolo]",
    "view": "Visualizza",
    "viewWorkspaceConfiguration": "Visualizza la configurazione del workspace",
    "welcome": "Benvenuto in Mirador",
    "window": "Finestra: {{label}}",
    "windowMenu": "Visualizzazioni finestra e display thumbnail",
    "windowNavigation": "Navigazione finestra",
    "windowPluginButtons": "Opzioni",
    "windowPluginMenu": "Opzioni finestra",
    "workspace": "Workspace",
    "workspaceFullScreen": "Schermo intero",
    "workspaceMenu": "Configurazioni Workspace",
    "workspaceNavigation": "Navigazione del workspace",
    "workspaceOptions": "Opzioni Workspace",
    "workspaceSelectionTitle": "Seleziona il tipo di workspace",
    "zoomIn": "Zoom in",
    "zoomOut": "Zoom out",
    "zoomReset": "Reset zoom"
  }
}
