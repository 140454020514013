{
  "translation": {
    "aboutMirador": "About Project Mirador",
    "aboutThisItem": "About this item",
    "addedFromUrl": "(Added from URL)",
    "addManifestUrl": "Resource location",
    "addManifestUrlHelp": "The URL of a IIIF resource",
    "addResource": "Add resource",
    "annotationCanvasLabel_1/1": "Item: [{{label}}]",
    "annotationCanvasLabel_1/2": "Left: [{{label}}]",
    "annotationCanvasLabel_2/2": "Right: [{{label}}]",
    "annotations": "Annotations",
    "attribution": "Attribution",
    "attributionTitle": "Rights",
    "authenticationFailed": "Authentication failed.",
    "authenticationRequired": "Authentication required for full access",
    "backToResults": "Back to results",
    "book": "Book",
    "bottom": "Bottom",
    "cancel": "Cancel",
    "canvasIndex": "Index",
    "changeTheme": "Change theme",
    "clearSearch": "clear",
    "close": "Close",
    "closeAddResourceForm": "Close form",
    "closeAddResourceMenu": "Close resource list",
    "closeCompanionWindow": "Close panel",
    "closeWindow": "Close window",
    "collapseSection": "Collapse \"{{section}}\" section",
    "collapseSidePanel": "Collapse sidebar",
    "collection": "Collection",
    "itemList": "Item list",
    "continue": "Continue",
    "copy": "Copy",
    "currentItem": "Current item",
    "currentItem_1/1": "Current item",
    "currentItem_1/2": "Left",
    "currentItem_2/2": "Right",
    "dark": "Dark theme",
    "digitizedView": "Digitized view",
    "dismiss": "Dismiss",
    "highlightAllAnnotations": "Highlight all",
    "displayNoAnnotations": "Highlight none",
    "downloadExport": "Export workspace",
    "downloadExportWorkspace": "Export workspace",
    "elastic": "Elastic",
    "elasticDescription": "Move and size windows freely in an unlimited workspace. Windows can overlap.",
    "emptyResourceList": "Your resource list is empty",
    "error": "Error",
    "errorDialogConfirm": "OK",
    "errorDialogTitle": "An error occurred",
    "exitFullScreen": "Exit full screen",
    "expandSection": "Expand \"{{section}}\" section",
    "expandSidePanel": "Expand sidebar",
    "exportCopied": "The workspace configuration was copied to your clipboard",
    "fetchManifest": "Add",
    "fullScreen": "Full Screen",
    "gallery": "Gallery",
    "hideZoomControls": "Hide zoom controls",
    "iiif_homepage": "About this resource",
    "iiif_manifest": "IIIF manifest",
    "iiif_renderings": "Alternate formats",
    "iiif_seeAlso": "See also",
    "import" : "Import",
    "importWorkspace": "Import workspace",
    "importWorkspaceHint": "Paste a Mirador 3 configuration to be imported",
    "item": "Item: {{label}}",
    "jsError": "Technical details",
    "jsStack": "{{ stack }}",
    "language": "Language",
    "layer_hide": "Hide layer",
    "layer_move": "Move layer",
    "layer_opacity": "Layer opacity",
    "layer_show": "Show layer",
    "layer_moveToTop": "Move layer to top",
    "layers": "Layers",
    "light": "Light theme",
    "links": "Links",
    "listAllOpenWindows": "Jump to window",
    "login": "Log in",
    "logout": "Log out",
    "manifestError": "The resource cannot be added:",
    "maximizeWindow": "Maximize window",
    "minimizeWindow": "Minimize window",
    "mirador": "Mirador",
    "miradorResources": "Mirador resources",
    "miradorViewer": "Mirador viewer",
    "more": "more...",
    "moreResults": "More results",
    "mosaic": "Mosaic",
    "mosaicDescription": "Move and size windows in relation to each other, within the visible frame.",
    "moveCompanionWindowToBottom": "Move to bottom",
    "moveCompanionWindowToRight": "Move to right",
    "nextCanvas": "Next item",
    "noItemSelected": "No item selected",
    "numItems": "{{number}} item",
    "numItems_plural": "{{number}} items",
    "off": "Off",
    "openCompanionWindow_annotations": "Annotations",
    "openCompanionWindow_attribution": "Rights",
    "openCompanionWindow_canvas": "Index",
    "openCompanionWindow_info": "Information",
    "openCompanionWindow_layers": "Layers",
    "openCompanionWindow_search": "Search",
    "openInCompanionWindow": "Open in separate panel",
    "openWindows": "Current open windows",
    "pagination": "{{current}} of {{total}}",
    "position": "Position",
    "previewWindowTitle": "{{title}}",
    "previousCanvas": "Previous item",
    "related": "Related",
    "resource": "Resource",
    "retry": "Retry",
    "right": "Right",
    "rights": "License",
    "scroll": "Scroll",
    "searchInputLabel": "search terms",
    "searchNextResult": "Next result",
    "searchNoResults": "No results found",
    "searchPreviousResult": "Previous result",
    "searchResultsRemaining": "{{numLeft}} remaining",
    "searchSubmitAria": "Submit search",
    "searchTitle": "Search",
    "selectWorkspaceMenu": "Select workspace type",
    "showingNumAnnotations": "Showing {{number}} annotation",
    "showingNumAnnotations_plural": "Showing {{number}} annotations",
    "showCollection": "Show collection",
    "showZoomControls": "Show zoom controls",
    "sidebarPanelsNavigation": "Sidebar panels navigation",
    "single": "Single",
    "startHere": "Start Here",
    "suggestSearch": "Search this document for \"{{ query }}\"",
    "tableOfContentsList": "Table of contents",
    "theme": "Theme",
    "thumbnailList": "Thumbnail list",
    "thumbnailNavigation": "Thumbnails",
    "thumbnails": "Thumbnails",
    "toggleWindowSideBar": "Toggle sidebar",
    "totalCollections": "{{count}} collection",
    "totalCollections_plural": "{{count}} collections",
    "totalManifests": "{{count}} manifest",
    "totalManifests_plural": "{{count}} manifests",
    "tryAgain": "Try again",
    "untitled": "[Untitled]",
    "view": "View",
    "viewWorkspaceConfiguration": "View workspace configuration",
    "welcome": "Welcome to Mirador",
    "window": "Window: {{label}}",
    "windowMenu": "Window views & thumbnail display",
    "windowNavigation": "Window navigation",
    "windowPluginButtons": "Options",
    "windowPluginMenu": "Window options",
    "workspace": "Workspace",
    "workspaceNavigation": "Workspace navigation",
    "workspaceFullScreen": "Full screen",
    "workspaceMenu": "Workspace settings",
    "workspaceOptions": "Workspace options",
    "workspaceSelectionTitle": "Select a workspace type",
    "zoomIn": "Zoom in",
    "zoomOut": "Zoom out",
    "zoomReset": "Reset zoom"
  }
}
